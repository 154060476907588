var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "publicDialog aiSetting",
      attrs: {
        visible: _vm.localVisible,
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "70%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.localVisible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "headerFlex" }, [
          _c("div", { staticClass: "headerTitle" }, [
            _vm._v(_vm._s(_vm.$t("aiSet.systemSetting"))),
          ]),
          _c("i", { staticClass: "el-icon-close", on: { click: _vm.close } }),
        ]),
      ]),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c("div", { staticClass: "set-title" }, [
            _vm._v(_vm._s(_vm.$t("aiSet.base"))),
          ]),
          _c(
            "el-row",
            { attrs: { gutter: 36 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("aiSet.name"), prop: "videoName" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "50",
                          placeholder: _vm.$t("tip.input"),
                        },
                        model: {
                          value: _vm.form.videoName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "videoName", $$v)
                          },
                          expression: "form.videoName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("aiSet.location"),
                        prop: "productTypeId",
                      },
                    },
                    [
                      _c("treeselect", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          options: _vm.productTypelist,
                          normalizer: _vm.normalizer,
                          "load-options": _vm.loadOptions,
                          clearable: false,
                          placeholder: _vm.$t("aiSet.tipLocation"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "value-label",
                            fn: function (ref) {
                              var node = ref.node
                              return [_vm._v(" " + _vm._s(node.raw.name) + " ")]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.form.productTypeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "productTypeId", $$v)
                          },
                          expression: "form.productTypeId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "LOGO", prop: "logo" } },
                    [
                      _c("AiSetPicUpload", {
                        attrs: { isSquare: true, url: _vm.form.logo },
                        on: {
                          picSelectMaterial: function ($event) {
                            return _vm.chooseMaterial(3)
                          },
                          showLocaPic: _vm.showLocaPic,
                          clearUrl: function ($event) {
                            return _vm.clearUrl(3)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "set-title" }, [
            _vm._v(_vm._s(_vm.$t("aiSet.videoSet"))),
          ]),
          _c(
            "el-row",
            { attrs: { gutter: 36 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("aiSet.bgm"),
                        prop: "backgroundMusicUrl",
                      },
                    },
                    [
                      _vm.form.backgroundMusicUrl
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex-row-center f-between ai-height",
                            },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: _vm.$t("editVideo.preview"),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex-row-center f-grow hidden pointer",
                                      on: { click: _vm.playAudio },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "iconfont icon-yinpinsucai f-shrink",
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "single-line-ellipsis f-grow",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("tip.selected")))]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("i", {
                                staticClass:
                                  "iconfont icon-guanbi1 f-shrink pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.clearUrl(2)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "flex-row-center f-between ai-height pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.chooseMaterial(2)
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("tip.select"))),
                              ]),
                              _c("i", {
                                staticClass: "iconfont icon-xialajiantou",
                              }),
                            ]
                          ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("aiSet.speechVoice"),
                        prop: "speechVoice",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-row-center f-between ai-height pointer",
                          on: { click: _vm.openVoice },
                        },
                        [
                          _vm.form.speechVoice
                            ? _c("div", { staticClass: "flex-row-center" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getVoiceLabel(_vm.form.speechVoice)
                                    ) +
                                    " "
                                ),
                                _c("i", {
                                  staticClass:
                                    "iconfont icon-guanbi1 f-shrink pointer ml10",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.clearUrl(8)
                                    },
                                  },
                                }),
                              ])
                            : _c("div", [_vm._v(_vm._s(_vm.$t("tip.select")))]),
                          _c("span", { staticClass: "voice-btn" }, [
                            _vm._v(
                              _vm._s(
                                _vm.form.speechVoice
                                  ? _vm.$t("change")
                                  : _vm.$t("select")
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("aiSet.color"),
                        prop: "backgroundColor",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-row-center ai-height" },
                        [
                          _c("el-color-picker", {
                            attrs: { predefine: _vm.predefinedColors },
                            model: {
                              value: _vm.form.backgroundColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "backgroundColor", $$v)
                              },
                              expression: "form.backgroundColor",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("mixing.cover"),
                        prop: "coverUrl",
                      },
                    },
                    [
                      _c("AiSetPicUpload", {
                        attrs: {
                          isSquare: false,
                          url: _vm.form.coverUrl,
                          type: _vm.form.coverType,
                        },
                        on: {
                          picSelectMaterial: function ($event) {
                            return _vm.chooseMaterial(4)
                          },
                          showLocaPic: _vm.showLocaPic,
                          clearUrl: function ($event) {
                            return _vm.clearUrl(4)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("mixing.backCover"),
                        prop: "backCoverUrl",
                      },
                    },
                    [
                      _c("AiSetPicUpload", {
                        attrs: {
                          isSquare: false,
                          url: _vm.form.backCoverUrl,
                          type: _vm.form.backCoverType,
                        },
                        on: {
                          picSelectMaterial: function ($event) {
                            return _vm.chooseMaterial(5)
                          },
                          showLocaPic: _vm.showLocaPic,
                          clearUrl: function ($event) {
                            return _vm.clearUrl(5)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("mixing.transition"),
                        prop: "transitionList",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-row-center f-between ai-height pointer",
                          on: { click: _vm.openTransition },
                        },
                        [
                          _vm.form.transitionList
                            ? _c("div", { staticClass: "flex-row-center" }, [
                                _c("img", {
                                  staticClass: "transition-img",
                                  attrs: {
                                    src: _vm.getTransitionUrl(
                                      _vm.form.transitionList
                                    ),
                                  },
                                }),
                                _c("i", {
                                  staticClass:
                                    "iconfont icon-guanbi1 f-shrink pointer ml10",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.clearUrl(7)
                                    },
                                  },
                                }),
                              ])
                            : _c("div", [_vm._v(_vm._s(_vm.$t("tip.select")))]),
                          _c("span", { staticClass: "voice-btn" }, [
                            _vm._v(
                              _vm._s(
                                _vm.form.transitionList
                                  ? _vm.$t("change")
                                  : _vm.$t("select")
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "set-title" }, [
            _vm._v(_vm._s(_vm.$t("aiSet.subtitle"))),
          ]),
          _c(
            "el-row",
            { attrs: { gutter: 36 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("aiSet.font"), prop: "fontType" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: _vm.$t("subtitle.tipFont"),
                            "default-first-option": true,
                          },
                          model: {
                            value: _vm.form.fontType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fontType", $$v)
                            },
                            expression: "form.fontType",
                          },
                        },
                        _vm._l(_vm.dict.type.font_typeface, function (dict) {
                          return _c("el-option", {
                            key: dict.value,
                            attrs: { label: dict.label, value: dict.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("aiSet.fontSize"),
                        prop: "fontSizeType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("subtitle.tipFont"),
                            "default-first-option": true,
                          },
                          on: { change: _vm.changeFontSize },
                          model: {
                            value: _vm.form.fontSizeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fontSizeType", $$v)
                            },
                            expression: "form.fontSizeType",
                          },
                        },
                        _vm._l(_vm.fontSizeList, function (dict) {
                          return _c("el-option", {
                            key: dict.value,
                            attrs: { label: dict.label, value: dict.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("aiSet.letterSpacing"),
                        prop: "spacing",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "number",
                          max: 20,
                          min: 0,
                          placeholder: _vm.$t("tip.input"),
                        },
                        on: { input: _vm.spacingInput },
                        model: {
                          value: _vm.form.spacing,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "spacing", $$v)
                          },
                          expression: "form.spacing",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("aiSet.textColor"),
                        prop: "fontColor",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-row-center ai-height" },
                        [
                          _c("el-color-picker", {
                            model: {
                              value: _vm.form.fontColor,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "fontColor", $$v)
                              },
                              expression: "form.fontColor",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("aiSet.center"),
                        prop: "alignment",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("tip.select"),
                            "default-first-option": true,
                          },
                          model: {
                            value: _vm.form.alignment,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "alignment", $$v)
                            },
                            expression: "form.alignment",
                          },
                        },
                        _vm._l(_vm.positionList, function (dict) {
                          return _c("el-option", {
                            key: dict.value,
                            attrs: { label: dict.label, value: dict.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("aiSet.decoratedText"),
                        prop: "effectColorStyleUrl",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-row-center f-between ai-height pointer",
                          on: { click: _vm.openColorStyle },
                        },
                        [
                          _vm.form.effectColorStyle
                            ? _c("div", { staticClass: "flex-row-center" }, [
                                _c("img", {
                                  staticClass: "effectColorStyle-img",
                                  attrs: { src: _vm.form.effectColorStyleUrl },
                                }),
                                _c("i", {
                                  staticClass:
                                    "iconfont icon-guanbi1 f-shrink pointer ml10",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.clearUrl(6)
                                    },
                                  },
                                }),
                              ])
                            : _c("div", [_vm._v(_vm._s(_vm.$t("tip.select")))]),
                          _c("span", { staticClass: "voice-btn" }, [
                            _vm._v(
                              _vm._s(
                                _vm.form.effectColorStyle
                                  ? _vm.$t("change")
                                  : _vm.$t("select")
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-row-center mt20 f-end" },
        [
          _c(
            "el-button",
            { staticClass: "cancelBtn btnWidth120", on: { click: _vm.close } },
            [_vm._v(_vm._s(_vm.$t("cancel")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "submitBtn btnWidth120",
              attrs: { loading: _vm.setLoading },
              on: { click: _vm.submit },
            },
            [_vm._v(_vm._s(_vm.$t("confirm")))]
          ),
        ],
        1
      ),
      _c("material-drawer", {
        ref: "materialDrawer",
        attrs: { visible: _vm.drawer },
        on: {
          "update:visible": function ($event) {
            _vm.drawer = $event
          },
          selectionConfirmed: _vm.selectionConfirmed,
        },
      }),
      _c(
        "el-drawer",
        {
          staticClass: "publicDrawer",
          attrs: {
            "destroy-on-close": true,
            size: "65%",
            visible: _vm.voiceDrawer,
            "with-header": true,
            "before-close": _vm.handleCloseVoice,
            "append-to-body": true,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.voiceDrawer = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "headerFlex" }, [
              _c("div", { staticClass: "headerTitle" }, [
                _vm._v(_vm._s(_vm.$t("aiSet.speechVoice"))),
              ]),
            ]),
          ]),
          _c("Voice", {
            ref: "voiceRef",
            staticStyle: { padding: "20px" },
            attrs: {
              voiceList: _vm.voiceList,
              value: _vm.form.speechVoice,
              type: 1,
            },
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          staticClass: "publicDrawer",
          attrs: {
            "destroy-on-close": true,
            size: "65%",
            visible: _vm.transitionDrawer,
            "with-header": true,
            "append-to-body": true,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.transitionDrawer = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "headerFlex" }, [
              _c("div", { staticClass: "headerTitle" }, [
                _vm._v(_vm._s(_vm.$t("editVideo.transitionSetup"))),
              ]),
            ]),
          ]),
          _c("Transition", {
            staticClass: "ml20",
            attrs: { transitionList: _vm.transitionList, type: 1 },
            on: { "item-selected": _vm.transitionUpdated },
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          staticClass: "publicDrawer",
          attrs: {
            "destroy-on-close": true,
            size: "500px",
            visible: _vm.colorStyleDrawer,
            "with-header": true,
            "append-to-body": true,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.colorStyleDrawer = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "headerFlex" }, [
              _c("div", { staticClass: "headerTitle" }, [
                _vm._v(_vm._s(_vm.$t("aiSet.speechVoice"))),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "image-container" },
            _vm._l(_vm.effectColorStyleList, function (item) {
              return _c("img", {
                key: item.id,
                class: {
                  selected: _vm.form.effectColorStyle === item.effectColorStyle,
                },
                attrs: { src: item.imgUrl },
                on: {
                  click: function ($event) {
                    return _vm.selectEffectItem(item)
                  },
                },
              })
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }