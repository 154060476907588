var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "uploadboxPic",
    },
    [
      _c(
        "el-dropdown",
        { staticClass: "w-100", attrs: { trigger: "hover" } },
        [
          _c("div", { staticClass: "local flex-row-center f-between" }, [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.url ? _vm.$t("file.uploaded") : _vm.$t("file.clickUpload")
                )
              ),
            ]),
            _c("div", { staticClass: "flex-row-center" }, [
              _vm.url && _vm.type === "video"
                ? _c("video", {
                    class: _vm.isSquare ? "choosedPicSquare" : "choosedPic",
                    attrs: { src: _vm.url, alt: "logo and so on" },
                  })
                : _vm._e(),
              _vm.url && _vm.type === "image"
                ? _c("img", {
                    class: _vm.isSquare ? "choosedPicSquare" : "choosedPic",
                    attrs: { src: _vm.url, alt: "logo and so on" },
                  })
                : _vm._e(),
              _vm.url
                ? _c("i", {
                    staticClass: "iconfont icon-guanbi1 pointer",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("clearUrl")
                      },
                    },
                  })
                : _vm._e(),
              _c("i", { staticClass: "iconfont icon-daochu" }),
            ]),
          ]),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c(
                "el-dropdown-item",
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: "",
                        accept: "image/*",
                        "before-upload": _vm.handleBeforeUpload,
                        limit: 1,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("file.localUpload")) + " ")]
                  ),
                ],
                1
              ),
              _c("el-dropdown-item", [
                _c("div", { on: { click: _vm.picSelectMaterial } }, [
                  _vm._v(_vm._s(_vm.$t("file.selectMaterial"))),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }