<template>
  <div class="materials-container flex-column">
    <div class="right-panel f-grow">
      <div
        class="flex-row-center f-between mb32"
        style="height: 48px;"
        v-if="!skipInit||isSelectionMode==1||isSelectionMode==7||isSelectionMode==8"
      >
        <div class="leftActiveTab">
          <div
            v-for="item in tabList"
            :key="item.label"
            @click="tabClick(item)"
          >
            <div
              v-if="item.needShow"
              :class="['tabItem', item.isActive && 'activeTab']"
            > {{ item.label }}</div>
          </div>
        </div>
        <div
          v-if="quota.fileSize"
          class="flex-row-center ml20"
        >
          <span class="mr5">{{$t('quota.quota')}}：</span>
          <Slider
            :value="formatQuota(quota.useFileSize) || 0"
            :maxValue="formatQuota(quota.fileSize) ||'10G' "
          />
          <div class="ml5">
            {{ quota.useFileSize || 0}}/{{ quota.fileSize || '10G'}}
          </div>
        </div>
      </div>
      <div>
        <div class="flex-row-center f-between w-100">
          <div class="f-shrink">
            <!-- 创建文件夹按钮（第一层和第二层） -->
            <el-button
              class="gradient-button"
              icon="iconfont icon-daochu"
              @click="addText"
              v-if="isSelectionMode==0 && activeTab=='text' && checkCommon()"
            >{{ $t('copywriting.new') }}</el-button>
            <el-button
              class="gradient-button"
              icon="iconfont icon-daochu"
              @click="addMaterial"
              v-if="isSelectionMode==0 && (activeTab=='all'||activeTab=='video'||activeTab=='image'||activeTab=='audio') && checkCommon()"
            >{{ $t('material.upload') }}</el-button>
            <el-button
              class="normal-button"
              icon="iconfont icon-tianjia1"
              @click="openAddDialog"
              v-if="isSelectionMode==0 && currentLevel < 2 && checkCommon()"
            >{{$t('file.createFolder')}}</el-button>
            <el-button
              class="delete-button"
              icon="iconfont icon-delete"
              @click="deleteMultiple"
              v-if="isSelectionMode==0 && ids.length > 0"
            >{{$t('remove')}}</el-button>
            <div v-if="isSelectionMode!=0">
              <el-button
                v-if="isTextToVideo"
                class="gradient-button"
                @click="confirmSelection"
              >{{ $t('confirmSelection') }}</el-button>
            </div>
          </div>
          <div class="flex-row-center">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('refresh')"
              placement="top"
            >
              <i
                class="iconfont icon-a-danyuanpeizhi6"
                @click="refresh"
              ></i>
            </el-tooltip>
            <el-radio-group
              v-model="styleType"
              class="custom-radio-group"
              v-if="isSelectionMode==0"
            >
              <el-radio
                label="grid"
                :border="styleType === 'grid'"
              >
                <template v-slot:default>
                  <i
                    class="iconfont font20 icon-grid"
                    :class="styleType === 'grid' ? 'gradient-purple' : 'unbind'"
                  ></i>
                </template>
              </el-radio>
              <el-radio
                label="list"
                :border="styleType === 'list'"
              >
                <template v-slot:default>
                  <i
                    class="iconfont font20 icon-list"
                    :class="styleType === 'list' ? 'gradient-purple' : 'unbind'"
                  ></i>
                </template>
              </el-radio>
            </el-radio-group>
            <div :style="{'margin': '0 10px','width': isTextToVideo ? '200px' : '400px'}">
              <el-input
                class="account-input"
                v-model="queryParams.searchValue"
                :placeholder="activeTab == 'text' ? $t('copywriting.tipName') : $t('material.tipName')"
                @keyup.enter.native="handleQuery"
                @change="searchValueChange"
                clearable
              >
                <i
                  slot="prefix"
                  class="el-input__icon iconfont icon-sousuotubiao"
                  @click="handleQuery"
                ></i>
              </el-input>
            </div>
          </div>
          <!-- <el-form class="searchForm flex-row-center f-wrap" :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch">
            <el-form-item label="">
              <el-select v-model="queryParams.labels" @change="handleQuery" clearable
                         :placeholder="$t('label.label')" style="width: 200px" allow-create filterable multiple>
                <el-option v-for="dict in labelList" :key="dict.id" :label="dict.label" :value="dict.label"/>
              </el-select>
            </el-form-item>
          </el-form> -->
        </div>

        <div v-loading="loadingOther">
          <div class="file-system">
            <!-- 显示当前路径 -->
            <div
              class="flex-row-center fileSpace"
              v-if="currentPath.length > 0 || isSearchResult"
            >
              <div class="breadcrumb">
                <span
                  @click="goLast"
                  v-if="!isSearchResult"
                >← {{$t('file.goBack')}}</span>
                <span
                  class="ml10"
                  @click="goBack"
                >{{$t('file.goBackMaterial')}}&nbsp;/</span>
              </div>
              <div
                class="breadcrumb"
                style="margin-left: 5px;"
              >
                <span
                  v-for="(folder, index) in currentPathFolders"
                  :key="folder.id"
                  @click="navigateTo(index)"
                >
                  {{ folder.name }} <span v-if="index < currentPath.length - 1">&nbsp;/&nbsp;</span>
                </span>
              </div>
              <span
                v-if="isSearchResult"
                class="ml10"
              >{{ $t('file.searchResult') }}</span>
              <el-button
                class="delete-button ml20"
                icon="iconfont icon-guanbi1"
                @click="closeSearch"
                v-if="isSearchResult"
              >{{$t('file.closeSearch')}}</el-button>
            </div>
            <!-- 显示当前文件夹 -->
            <div
              class="flex-row f-wrap folders"
              v-if="styleType === 'grid' &&!isSearchResult"
              style="height: calc(100vh - 350px); overflow-y: auto;"
            >
              <folder
                class="video-parent"
                v-for="folder in filterFile"
                :key="folder.id"
                :folder="folder"
                @open-folder="openFolder"
                @edit-folder="openEditDialog"
                @delete-folder="deleteMaterialType"
              />
              <video-grid-item
                class="video-parent"
                v-for="(video, index) in materialList"
                :key="index.id"
                :ref="'videoItem-' + video.id"
                :video="video"
                :isSelectionMode="isSelectionMode!=0"
                :current-audio-id="currentAudioId"
                @play-audio="handlePlayAudio"
                @delete-video="deleteVideo"
                @edit-video="editVideo"
                @view-text="viewText"
                @edit-text="editText"
                @toggle-selection="handleToggleSelection"
              />
            </div>
          </div>
          <!-- 因为v-for和v-if不能同时使用，为了使搜索结果将文件夹列表分开，所以又写了一遍 -->
          <div
            class="flex-row f-wrap"
            v-if="styleType === 'grid'&& isSearchResult"
            style="height: calc(100vh - 350px); overflow-y: auto;"
          >
            <video-grid-item
              class="video-parent"
              v-for="(video, index) in materialList"
              :key="index.id"
              :ref="'videoItem-' + video.id"
              :video="video"
              :isSelectionMode="isSelectionMode!=0"
              :current-audio-id="currentAudioId"
              @play-audio="handlePlayAudio"
              @delete-video="deleteVideo"
              @edit-video="editVideo"
              @view-text="viewText"
              @edit-text="editText"
              @toggle-selection="handleToggleSelection"
            />
          </div>
          <div
            class="w-100 mt10"
            v-if="styleType === 'list'"
          >
            <video-list-table
              :videoList="mergedList"
              :isSelectionMode="isSelectionMode!=0"
              @open-folder="openFolder"
              @edit-folder="openEditDialog"
              @delete-folder="deleteMaterialType"
              @delete-video="deleteVideo"
              @view-text="viewText"
              @edit-video="editVideo"
              @edit-text="editText"
              @toggle-selection="handleToggleSelection"
              @select-multiple="handleSelectMultiple"
            />
          </div>
          <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            :pageSizes="[12,24,36,48,60]"
            @pagination="getMaterialList"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isSelectionMode!=0 && !isTextToVideo"
      class="f-shrik flex-center mt10"
      style="background:white"
    >
      <el-button
        class="button-white"
        @click="$emit('cancel')"
      >{{ $t('cancel') }}</el-button>
      <el-button
        class="gradient-button"
        @click="confirmSelection"
      >{{ $t('confirmSelection') }}</el-button>
    </div>
    <!--  -->
    <addFolderDialog
      :visible.sync="editDialogVisible"
      :title="editDialogTitle"
      :initialValue="initialInput"
      :initialLabel="initialLabel"
      :labelList="labelList"
      :isSystem="isSystem"
      :treeFileList="list"
      :materialTypeId="initialParentId"
      :editMaterialId="editMaterialId"
      @submitAddFile="handleConfirm"
    />
    <MaterialUploadDialog
      ref="materialUploadDialog"
      :visible.sync="uploadDialogVisible"
      :title="uploadTitle"
      :list="list"
      :labelList="labelList"
      :materialTypeId="currentTypeId"
      :isSystem="isSystemFile"
      :activeTab="activeTab"
      @closeUpload="closeUpload"
      @addMaterialSuccess="addMaterialSuccess"
      @editMaterialSuccess="editMaterialSuccess"
      @updateFile="updateFile"
    />
    <add-text-dialog
      ref="textDialog"
      :visible.sync="copywritingVisible"
      :title="copywritingTitle"
      :isSystemFile="isSystemFile"
      :label-list="labelList"
      :materialTypeList="list"
      :materialTypeId="currentTypeId"
      @submit="getMaterialList"
    />
  </div>
</template>

<script>
import VideoGridItem from "../../components/Material/VideoGridItem.vue";
import VideoListTable from "../../components/Material/VideoListTable.vue";
import addFolderDialog from "../../components/dialog/addFolderDialog.vue";
import Folder from "../../components/video/Folder.vue";
import Slider from "../account/slider.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { listMaterialType } from "../../api/ai/materialType";
import { listMaterial, removeMaterialAll } from "../../api/ai/material";
import { listLabel } from "../../api/system/label";
import { getQuota } from "../../api/system/merchant";
import MaterialUploadDialog from "../../components/video/MaterialUploadDialog.vue";
import AddTextDialog from "../../components/dialog/addTextDialog.vue";
import EventBus from "../../utils/EventBus";

export default {
  name: "Material",
  components: {
    VideoGridItem,
    VideoListTable,
    addFolderDialog,
    Folder,
    Slider,
    MaterialUploadDialog,
    Treeselect,
    AddTextDialog,
  },
  props: {
    skipInit: {
      type: Boolean,
      default: false,
    },
    timeLength: {
      type: Number,
      default: 0,
    },
    isTextToVideo: {
      //文生视频中音乐进来需要修改布局
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 按钮loading
      buttonLoading: false,
      // 遮罩层
      loading: false, //文案列表的loading
      loadingOther: false, //其他列表的loading
      activeTab: "all",
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 选中数组
      ids: [],
      usernames: [],
      isSelectionMode: 0, // 0-默认值,1-图片、视频多选，2-音频单选，3-图片单选、4-文案单选、5-视频可选(多选)、6-视频单选、7-图片、视频单选（默认图片）、8-图片、视频单选（默认视频）
      queryParams: {
        pageNum: 1,
        pageSize: 24,
        labels: [],
        label: "",
        searchValue: "",
        fileType: "", //image、video、audio
        materialTypeId: 0,
      },
      isSearchResult: false, //是否为搜索结果
      // 显示搜索条件
      showSearch: true,
      // list: [{
      //   id: 0,
      //   name: this.$t('basicInfo.materia'),
      //   type: 'system',
      // }],
      list: [],
      folders: [], //文件夹列表，与list相同，没有‘全部’
      // 总条数
      total: 0,
      totalCopywriting: 0,
      copywritingList: [],
      editDialogVisible: false,
      editDialogTitle: this.$t("file.editFolder"),
      editMaterialId: null,
      initialInput: "", //默认分类名称
      initialLabel: "", //默认分类标签
      isSystem: false, //是否为系统文件夹
      isSystemFile: false, //是否为系统文件
      initialParentId: 0, //默认父文件夹id
      uploadDialogVisible: false, //素材弹窗
      copywritingVisible: false, //文案弹窗
      copywritingForm: {
        title: "",
        content: "",
        label: "",
        labels: [],
        materialTypeId: 0,
        fileType: "text",
      },
      rulesCopywriting: {
        title: [
          {
            required: true,
            message: this.$t("copywriting.tipTitle"),
            trigger: ["blur", "change"],
          },
        ],
        content: [
          {
            required: true,
            message: this.$t("copywriting.tipContent"),
            trigger: ["blur", "change"],
          },
        ],
        // materialTypeId: [
        //   {required: true, message: this.$t('file.tipSelectFolder'), trigger: ['blur', 'change']}
        // ],
      },
      materialList: [],
      uploadTitle: this.$t("material.upload"), //弹窗标题：上传素材or编辑素材
      copywritingTitle: this.$t("copywriting.new"), //新增文案or编辑文案
      labelList: [], //标签
      drawer: false, //素材选择的抽屉
      quota: {}, //配额
      currentPath: [], // 用于追踪当前路径
      currentTypeId: 0, //当前打开的文件夹/素材id
      styleType: "grid", //grid or list
      selectedMultiple: [], //选中的素材
      currentAudioId: null, // 当前正在播放的音频 ID
      selectedOrder: [], // 新增：记录选中顺序的数组
    };
  },
  computed: {
    filterFile(list) {
      if (this.activeTab === "all") {
        return this.currentFolders;
      }
      if (this.activeTab === "audio") {
        // return this.currentFolders
        return this.currentFolders.filter((item) => item.dataType === "audio");
      }
      if (this.activeTab !== "audio" && this.activeTab !== "all") {
        return this.currentFolders.filter((item) => item.dataType !== "audio");
      }
    },
    currentLevel() {
      return this.currentPath.length;
    },
    currentFolders() {
      let level = this.folders;
      let validPath = true;

      this.currentPath.forEach((id) => {
        if (validPath) {
          const folder = level.find((folder) => folder.id === id);
          if (folder && folder.children) {
            level = folder.children;
          } else {
            validPath = false; // 如果路径无效，停止继续寻找
            level = [];
          }
        }
      });
      return level;
    },
    //list显示模式下的table数据
    mergedList() {
      // 使用 map 为 currentFolders 添加 type: 'folder'
      let foldersWithType = this.currentFolders.map((folder) => {
        // 处理 label 字段
        const labels = folder.label ? folder.label.split(",") : [];
        return {
          ...folder,
          documentType: "folder",
          labels, // 添加 labels 数组
        };
      });
      if (this.activeTab === "audio") {
        foldersWithType = foldersWithType.filter(
          (item) => item.dataType === "audio"
        );
      }
      if (this.activeTab !== "audio" && this.activeTab !== "all") {
        foldersWithType = foldersWithType.filter(
          (item) => item.dataType !== "audio"
        );
      }
      // 使用 map 为 materialList 添加 type: 'file'
      const materialsWithType = this.materialList.map((material) => ({
        ...material,
        documentType: "file",
      }));

      // 合并文件夹和文件，文件夹优先展示
      const newList = [...foldersWithType, ...materialsWithType];
      return newList;
    },
    currentPathFolders() {
      let pathFolders = [];
      let level = this.folders;
      this.currentPath.forEach((id) => {
        const folder = level.find((folder) => folder.id === id);
        if (folder) {
          pathFolders.push(folder);
          level = folder.children ? folder.children : [];
        }
      });
      return pathFolders;
    },
    tabList() {
      return [
        {
          label: this.$t("material.all"),
          isActive: true,
          name: "all",
          needShow: this.isSelectionMode === 0,
        },
        {
          label: this.$t("material.video"),
          isActive: false,
          name: "video",
          needShow: [0, 1, 5, 7, 8].includes(this.isSelectionMode),
        },
        {
          label: this.$t("material.picture"),
          isActive: false,
          name: "image",
          needShow: [0, 1, 3, 7, 8].includes(this.isSelectionMode),
        },
        {
          label: this.$t("material.audio"),
          isActive: false,
          name: "audio",
          needShow: this.isSelectionMode === 0 || this.isSelectionMode === 2,
        },
        {
          label: this.$t("material.copywriting"),
          isActive: false,
          name: "text",
          needShow: this.isSelectionMode === 0 || this.isSelectionMode === 4,
        },
      ];
    },
  },
  // beforeRouteUpdate(to, from, next) {
  //   if(to.query.folderId) {
  //     this.init(7)
  //   }
  //   next();
  // },
  // mounted() {
  //   // 初始化数据
  //   if (!this.skipInit) {
  //     if(this.$route.query.folderId) {
  //       this.init(7)
  //     } else {
  //       this.init(0)
  //     }
  //     this.getQuota()
  //   }
  //   this.getLabels()
  //   this.getMaterialTypeList();
  //   EventBus.$on('file-success', this.getMaterialList)
  //   EventBus.$on('matreial-menu-click', this.goBack)
  //   // EventBus.$on('get-material', this.getMaterialList)
  // },
  mounted() {
    // 初始化数据
    if (!this.skipInit) {
      this.init(0);
      this.getQuota();
    }
    this.getLabels();
    this.getMaterialTypeList();
    EventBus.$on("file-success", this.getMaterialList);
    EventBus.$on("matreial-menu-click", this.goBack);
    // EventBus.$on('get-material', this.getMaterialList)
  },
  beforeDestroy() {
    // this.closeAudio();
    // 在组件销毁前取消事件监听
    EventBus.$off("file-success", this.getMaterialList);
    EventBus.$off("matreial-menu-click", this.goBack);
  },
  methods: {
    handlePlayAudio(audioId) {
      // 更新当前播放的音频 ID
      this.currentAudioId = audioId;
      console.log("handlePlayAudio-this.currentAudioId=", this.currentAudioId);
    },
    // 检查当前文件夹是否为系统文件夹
    checkCommon() {
      if (this.userInfo.userType === "admin") return true;
      const item = this.folders.find((item) => item.id == this.currentTypeId);
      // 如果找到对应的 item 且 type 为 "system"，返回 false；否则返回 true
      return item ? item.type !== "system" : true;
    },
    // filterViewFile(item) {
    //   if ( this.activeTab === 'all') {
    //     return true
    //     // activeTab === 'audio' ? folder.dataType === 'audio' : activeTab === 'all' ? true : false
    //   }
    //   if (this.activeTab === 'audio' && item.dataType === 'audio') {
    //     return true
    //   }
    //   if (this.activeTab !== 'all' || activeTab !== 'audio' && item.dataType !== 'audio') {
    //     return true
    //   }

    //   // this.activeTab = 'audio'
    //   //   this.queryParams.fileType = 'audio'
    //   //   this.getMaterialList()
    //   // } else if (isSelectionMode == 3) {
    //   //   this.activeTab = 'image'
    //   //   this.queryParams.fileType = 'image'
    //   //   this.getMaterialList()
    //   // } else if (isSelectionMode == 4) {
    //   //   this.activeTab = 'text'
    //   //   this.queryParams.fileType = 'text'
    //   //   this.getMaterialList()
    //   // } else if (isSelectionMode == 5) {
    //   //   this.activeTab = 'video'
    //   //   this.queryParams.fileType = 'video'
    // },
    updateFile() {
      this.getMaterialTypeList();
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      const countLabel =
        node.materialCount > 0 ? `  (${node.materialCount})` : "";
      return {
        id: node.id,
        label: `${node.name}${countLabel}`,
        children: node.children,
      };
    },
    openFolder(folder) {
      this.currentTypeId = folder.id;
      this.currentPath.push(folder.id);
      console.log(this.currentPath);
      this.handleItemClick(folder.id);
    },
    // 上一步
    goLast() {
      if (this.currentPath.length > 0) {
        this.currentPath.pop();
        let id =
          this.currentPath.length > 0
            ? this.currentPath[this.currentPath.length - 1]
            : 0;
        this.currentTypeId = id;
        this.handleItemClick(id);
      }
    },
    // 返回素材库
    goBack() {
      if (this.currentPath.length > 0) {
        this.currentPath = [];
        // let id = this.currentPath.length > 0 ? this.currentPath[this.currentPath.length - 1] : 0
        // this.currentTypeId = id
        this.currentTypeId = 0;
        this.handleItemClick(0);
      }
    },
    navigateTo(index) {
      this.currentPath = this.currentPath.slice(0, index + 1);
    },
    handleInput(value) {
      if (value != null) {
        var label = value[value.length - 1];
        if (label != null && label.length > 20) {
          value[value.length - 1] = label.substring(0, 20);
        }
      }
    },
    //获取配额
    getQuota() {
      getQuota().then((response) => {
        this.quota = response.data;
      });
    },

    getLabels() {
      listLabel().then((response) => {
        this.labelList = response.rows;
      });
    },
    refresh() {
      if (!this.labelList || this.labelList.length == 0) {
        this.getLabels();
      }
      if (!this.folders || this.folders.length == 0) {
        this.getMaterialTypeList();
      }
      this.getMaterialList();
    },
    init(isSelectionMode) {
      this.isSelectionMode = isSelectionMode;
      if (isSelectionMode == 0) {
        this.activeTab = "all";
        this.queryParams.fileType = "";
        this.getMaterialList();
      } else if (
        isSelectionMode == 1 ||
        isSelectionMode == 5 ||
        isSelectionMode == 6 ||
        isSelectionMode == 8
      ) {
        this.activeTab = "video";
        this.queryParams.fileType = "video";
        this.getMaterialList();
      } else if (isSelectionMode == 2) {
        this.activeTab = "audio";
        this.queryParams.fileType = "audio";
        this.getMaterialList();
      } else if (isSelectionMode == 3 || isSelectionMode == 7) {
        this.activeTab = "image";
        this.queryParams.fileType = "image";
        this.getMaterialList();
      } else if (isSelectionMode == 4) {
        this.activeTab = "text";
        this.queryParams.fileType = "text";
        this.getMaterialList();
      } else {
        this.getMaterialList();
      }
      this.changeTab();
    },
    // 关闭搜索
    closeSearch() {
      this.isSearchResult = false;
      this.queryParams.searchValue = null;
      this.getMaterialList();
    },
    searchValueChange() {
      if (this.isSearchResult && !this.queryParams.searchValue) {
        this.isSearchResult = false;
        this.getMaterialList();
      }
    },
    //素材列表（视频、图片）
    getMaterialList() {
      this.loadingOther = true;
      if (this.queryParams.materialTypeId == 0) {
        this.queryParams.materialTypeId = null;
      }
      if (this.queryParams.searchValue) {
        this.isSearchResult = true;
      } else {
        this.isSearchResult = false;
      }
      listMaterial(this.queryParams)
        .then((response) => {
          this.materialList = response.rows;
          this.total = response.total;
          this.materialList.forEach((item) => {
            item.selected = false;
            item.hover = false;
            if (item.label) {
              item.labels = item.label.split(",");
            } else {
              item.labels = [];
            }
          });
        })
        .finally(() => {
          this.loadingOther = false;
        });
    },
    closeUpload() {
      this.uploadDialogVisible = false;
    },
    //新增素材（视频、图片）
    addMaterial() {
      if (this.isExceedQuota(this.quota.useFileSize, this.quota.fileSize)) {
        this.$modal.msgError(this.$t("material.exceedQuota"));
        return;
      }
      this.isSystemFile = false;
      this.uploadTitle = this.$t("material.upload");
      this.$nextTick().then((rs) => {
        this.$refs.materialUploadDialog.resetForm();
        this.uploadDialogVisible = true;
      });
    },
    //编辑素材（视频、图片）
    editVideo(item) {
      this.isSystemFile = item.type == "system";
      this.uploadTitle = this.$t("material.edit");
      this.$nextTick().then((rs) => {
        this.uploadDialogVisible = true;
        this.$refs.materialUploadDialog.init(item);
      });
    },
    //新增素材成功
    addMaterialSuccess(form) {
      if (this.activeTab != form.fileType) {
        this.activeTab = form.fileType;
        this.changeTab();
        this.queryParams.fileType = form.fileType;
        this.currentPath = [];
        this.materialList = [];
        this.selectedOrder = [];
      }
      this.queryParams.materialTypeId = form.materialTypeId || 0;
      this.openFolderById(this.queryParams.materialTypeId);
      this.getMaterialList();
      this.getLabels();
    },
    //编辑素材成功
    editMaterialSuccess({ fileAddressName }) {
      if (fileAddressName) {
        this.$modal.msgSuccess(
          `${this.$t("file.addedTo")}${fileAddressName}${this.$t(
            "file.folder"
          )}`
        );
      }
      this.getLabels(); //新增或编辑时可能对标签产生修改，需要刷新
      this.getMaterialTypeList(); //新增或编辑时会对文件数量产生修改，需要刷新
      this.getMaterialList();
    },
    openFolderById(targetId) {
      // 辅助函数，用于递归查找目标文件夹，并返回路径
      const findFolderPath = (folders, id, path = []) => {
        for (const folder of folders) {
          const newPath = [...path, folder.id]; // 生成新的路径
          if (folder.id === id) {
            return newPath; // 找到目标文件夹，返回路径
          }
          if (folder.children) {
            const result = findFolderPath(folder.children, id, newPath);
            if (result) {
              return result; // 递归查找子文件夹
            }
          }
        }
        return null; // 未找到返回 null
      };

      // 从根文件夹开始查找目标文件夹路径
      const path = findFolderPath(this.folders, targetId);
      if (path) {
        this.currentPath = path; // 更新 currentPath
      } else {
        console.error(`Folder with ID ${targetId} not found.`);
      }
    },
    //删除素材（视频、图片）
    deleteVideo(video) {
      this.$confirm(this.$t("material.tipDelete"), this.$t("tip.tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        // 处理删除逻辑
        removeMaterialAll({ ids: [video.id] }).then((response) => {
          this.$modal.msgSuccess(this.$t("tip.remove"));
          this.getMaterialList();
        });
      });
    },
    //选中素材
    toggleSelection(selected, video) {
      console.log("toggleSelection", selected);
      const index = this.materialList.findIndex((v) => v.id === video.id);
      if (index !== -1) {
        this.$set(this.materialList, index, {
          ...this.materialList[index],
          selected,
        });
        // this.materialList[index].selected = selected;
      }
      console.log("this.materialList", this.materialList);
    },
    //单选
    toggleSelectionSingle(selected, video) {
      console.log("toggleSelectionSingle", selected);
      // 取消所有其他项目的选择
      this.materialList = this.materialList.map((v) => ({
        ...v,
        selected: false,
      }));

      // 选择当前点击的项目
      const index = this.materialList.findIndex((v) => v.id === video.id);
      if (index !== -1) {
        this.$set(this.materialList, index, {
          ...this.materialList[index],
          selected,
        });
      }
      console.log("this.materialList", this.materialList);
    },
    //多选、单选都存在的情况，需要用方法重新处理下逻辑，直接在模板中使用三元运算符会导致事件绑定的问题，致使方法没有响应
    handleToggleSelection(selected, item) {
      if (
        this.isSelectionMode === 2 ||
        this.isSelectionMode === 3 ||
        this.isSelectionMode === 4 ||
        this.isSelectionMode === 6 ||
        this.isSelectionMode === 7 ||
        this.isSelectionMode === 8
      ) {
        this.toggleSelectionSingle(selected, item);
      } else {
        // 多选模式
        if (selected) {
          // 如果是选中，添加到选中顺序数组
          if (!this.selectedOrder.includes(item.id)) {
            this.selectedOrder.push(item.id);
          }
        } else {
          // 如果是取消选中，从选中顺序数组中移除
          const index = this.selectedOrder.indexOf(item.id);
          if (index > -1) {
            this.selectedOrder.splice(index, 1);
          }
        }
        this.toggleSelection(selected, item);
      }
    },
    //新增文案
    addText() {
      this.isSystemFile = false;
      this.copywritingTitle = this.$t("copywriting.new");
      this.resetCopywritingForm();
      this.$nextTick().then((rs) => {
        this.copywritingVisible = true;
        this.$refs.textDialog.init(this.copywritingForm);
      });
    },
    resetCopywritingForm() {
      this.copywritingForm = {
        id: null,
        title: null,
        content: null,
        label: null,
        labels: [],
        materialTypeId: this.currentTypeId,
        fileType: "text",
      };
    },
    //编辑文案
    editText(row) {
      this.isSystemFile = row.type == "system";
      this.copywritingTitle = this.$t("copywriting.edit");
      this.copywritingForm = { ...row }; // 使用浅拷贝确保数据绑定正确
      if (this.copywritingForm.label) {
        this.copywritingForm.labels = this.copywritingForm.label.split(",");
      }
      this.$nextTick().then((rs) => {
        this.copywritingVisible = true;
        this.$refs.textDialog.init(this.copywritingForm);
      });
    },
    // 查看文案
    viewText(row) {
      this.$router.push({
        path: `/materialDetail/${row.id}`,
      });
    },
    //素材分类列表
    getMaterialTypeList() {
      listMaterialType().then((response) => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.folders = response.data.sort((a, b) => {
            if (a.type === "system" && b.type !== "system") {
              return -1;
            }
            if (a.type !== "system" && b.type === "system") {
              return 1;
            }
            return 0;
          });
          this.folders.forEach((item) => {
            item.hover = false;
          });
          this.list = JSON.parse(JSON.stringify(response.data)).filter(
            (item) => item.type !== "system"
          );
          this.list.unshift({
            id: 0,
            name: this.$t("basicInfo.materia"),
            type: "system",
          });
        }
      });
    },
    // 仅修改tab的状态
    changeTab() {
      this.tabList.forEach((tabItem) => {
        if (this.activeTab === tabItem.name) {
          tabItem.isActive = true;
        } else {
          tabItem.isActive = false;
        }
      });
    },
    tabClick(item) {
      this.activeTab = item.name;
      this.changeTab();
      if (this.activeTab === "all") {
        this.queryParams.fileType = "";
      } else {
        if (this.activeTab === "audio") {
          this.queryParams.dataType = "audio";
        } else {
          this.queryParams.dataType = "";
        }
        this.queryParams.fileType = this.activeTab;
      }
      this.tabResetQuery();
    },
    tabResetQuery() {
      this.materialList = [];
      this.selectedOrder = [];
      // this.currentPath = []
      this.resetQuery();
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getMaterialList();
    },
    //素材重置查询
    resetQuery() {
      this.queryParams.labels = [];
      this.queryParams.label = "";
      this.queryParams.name = "";
      this.queryParams.title = "";
      this.queryParams.materialTypeId = this.currentTypeId;

      this.getMaterialList();
      if (this.folders.length == 0) {
        this.getMaterialTypeList();
      }
    },
    handleItemClick(index) {
      // 处理菜单项点击的逻辑
      console.log("Clicked item with index:", index);
      this.materialList = [];
      this.selectedOrder = [];
      this.queryParams.materialTypeId = index;
      this.getMaterialList();
    },
    openEditDialog(item) {
      console.log("item", item);
      this.initialInput = item.name;
      this.initialLabel = item.label;
      this.isSystem = item.type == "system";
      this.initialParentId = item.parentId || 0;
      this.editMaterialId = item.id;
      this.editDialogTitle = this.$t("file.editFolder");
      this.editDialogVisible = true;
    },
    openAddDialog() {
      this.initialInput = "";
      this.initialLabel = "";
      this.isSystem = false;
      this.initialParentId =
        (this.currentPath &&
          this.currentPath.length > 0 &&
          this.currentPath[this.currentPath.length - 1]) ||
        0;
      this.editMaterialId = null;
      this.editDialogTitle = this.$t("file.createFolder");
      this.editDialogVisible = true;
    },
    handleConfirm() {
      this.getMaterialTypeList();
    },
    deleteMaterialType(item) {
      this.$confirm(this.$t("file.tipDeleteFolderSingle"), this.$t("tip.tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        // 处理删除逻辑
        removeMaterialAll({ typeIds: [item.id] }).then((response) => {
          this.$modal.msgSuccess(this.$t("tip.remove"));
          this.getMaterialTypeList();
        });
      });
    },
    // 多选框选中数据
    handleSelectMultiple(selection) {
      this.selectedMultiple = selection || [];
      this.ids = selection.map((item) => item.id) || [];
    },
    // 批量删除
    deleteMultiple() {
      // 判断选中的项中是否有 documentType 为 'system' 的项
      const hasSystemDocumentType = this.selectedMultiple.some(
        (item) => item.documentType === "folder"
      );
      const tip = hasSystemDocumentType
        ? this.$t("file.tipDeleteSystemFolder")
        : this.$t("file.tipDeleteFolder");
      this.$confirm(tip, this.$t("tip.tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        const typeIds =
          this.selectedMultiple
            .filter((item) => item.documentType === "folder")
            .map((item) => item.id) || [];
        const ids =
          this.selectedMultiple
            .filter((item) => item.documentType !== "folder")
            .map((item) => item.id) || [];
        removeMaterialAll({ typeIds, ids }).then((response) => {
          this.$modal.msgSuccess(this.$t("tip.remove"));
          this.getMaterialTypeList();
          this.getMaterialList();
        });
      });
    },
    // 确认选择操作
    confirmSelection() {
      let selectedItems = this.materialList.filter((item) => item.selected);
      if (selectedItems.length == 0) {
        this.$modal.msgError(this.$t("material.tipVideo"));
        return;
      }

      // 根据选中顺序重新排序
      if (this.selectedOrder.length > 0) {
        selectedItems.sort((a, b) => {
          return (
            this.selectedOrder.indexOf(a.id) - this.selectedOrder.indexOf(b.id)
          );
        });
      }

      if (
        this.timeLength > 0 &&
        selectedItems[0].videoLength < this.timeLength
      ) {
        this.$message.error(this.$t("material.timeLimit"));
        return;
      }

      this.$emit("selectionConfirmed", selectedItems);
    },
    confirmText(row) {
      this.$emit("selectionConfirmed", row);
    },
    // 关闭当前播放音频
    closeAudio() {
      console.log("closeAudio-this.currentAudioId=", this.currentAudioId);
      if (this.currentAudioId) {
        this.$nextTick().then((rs) => {
          const videoItem = this.$refs["videoItem-" + this.currentAudioId];
          if (videoItem) {
            console.log(videoItem);
            if (Array.isArray(videoItem) && videoItem.length > 0) {
              videoItem[0].stopAudio();
            } else {
              videoItem.stopAudio();
            }
            this.currentAudioId = null;
          } else {
            console.log("未找到对应的 videoItem");
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.materials-container {
  width: 100%;
  padding: 24px;
}
.right-panel {
  height: calc(100vh - 145px);
  overflow-y: auto;
}

.leftActiveTab {
  display: flex;
  .tabItem {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 22px;
    text-align: center;
    font-style: normal;
    margin-right: 24px;
    cursor: pointer;
  }
  .activeTab {
    font-weight: bold;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.9);
    background: linear-gradient(0deg, #0089ff 0%, #6e16d1 100%);
    background-clip: text; /* 无前缀标准写法 */
    -webkit-background-clip: text; /* 兼容 WebKit 浏览器 */
    -webkit-text-fill-color: transparent;
  }
}

.file-system {
  padding: 0;
}
.fileSpace {
  padding: 15px 0px 10px 5px;
}
.breadcrumb {
  margin-bottom: 0px;
}
.breadcrumb span {
  cursor: pointer;
  color: #00aaff;
}
.breadcrumb span:hover {
  text-decoration: underline;
}
.folders,
.files {
  margin-top: 10px;
}
.folders div,
.files div {
  cursor: pointer;
}
// .folders div:hover,
// .files div:hover {
//   text-decoration: underline;
// }
.custom-radio-group {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
}
.custom-radio-group ::v-deep.el-radio {
  margin-right: 5px;
}
.custom-radio-group ::v-deep.el-radio.is-bordered.is-checked {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}
.custom-radio-group ::v-deep.el-radio__input {
  display: none; /* 隐藏原点 */
}

.video-parent {
  width: calc(16.6% - 29px);
}
</style>
