var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "aiPage" },
    [
      _c("div", { staticClass: "aiHeader flex-row-center f-between" }, [
        _c(
          "div",
          { staticClass: "aiHeaderTitle" },
          [
            _vm._v(_vm._s(_vm.$t("ai.headerTitle")) + " "),
            _c(
              "el-popover",
              {
                attrs: {
                  placement: "bottom-start",
                  width: "400",
                  trigger: "hover",
                  content: _vm.$t("ai.headerTitleTip"),
                },
              },
              [
                _c("i", {
                  staticClass: "iconfont icon-zhushi",
                  attrs: { slot: "reference" },
                  slot: "reference",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "header-setting flex-row-center",
            on: { click: _vm.goSetting },
          },
          [_c("i", { staticClass: "el-icon-s-tools" }), _vm._v(" 系统设置 ")]
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.recordLoading,
              expression: "recordLoading",
            },
          ],
          staticClass: "container flex-center",
        },
        [
          _c("div", { staticClass: "chat-container" }, [
            _vm.messages.length
              ? _c(
                  "div",
                  { staticClass: "messages" },
                  [
                    _vm._l(_vm.messages, function (message, index) {
                      return _c("ChatMessage", {
                        key: index,
                        attrs: { message: message },
                        on: {
                          submitTheme: _vm.submitTheme,
                          againTheme: _vm.againTheme,
                          submitScript: _vm.submitScript,
                          againVideo: _vm.againVideo,
                          submitVideo: _vm.submitVideo,
                          chooseVideo: _vm.chooseVideo,
                          submitAccount: _vm.submitAccount,
                          submitPost: _vm.submitPost,
                        },
                      })
                    }),
                    _vm.loading
                      ? _c("div", { staticClass: "flex-column" }, [
                          _c(
                            "div",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _vm._v(_vm._s(_vm.$t("ai.aiWaitMsg")) + " "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "link-type",
                                  attrs: { to: "/videoManager/videoManager" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("workbench.videoProduction"))
                                  ),
                                ]
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.$t("ai.otherTasksMsg")) + " "
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                )
              : _c("div", { staticClass: "defaultPrompt" }, [
                  _c("img", { attrs: { src: _vm.makeVideoLogo } }),
                  _c("div", { staticClass: "defaultTitle" }, [
                    _vm._v(_vm._s(_vm.$t("ai.defaultTitle1"))),
                  ]),
                  _c("div", { staticClass: "defaultContent" }, [
                    _vm._v(" " + _vm._s(_vm.$t("ai.defaultTitle2")) + " "),
                  ]),
                ]),
            _c(
              "div",
              { staticClass: "flex-center flexCenter" },
              [
                _c("ChatInput", {
                  attrs: { disabled: _vm.disabled },
                  on: {
                    playAudioLoca: _vm.playAudioLoca,
                    send: _vm.handleSend,
                    sendUrl: _vm.handleSendUrl,
                    setLoading: _vm.setRecordLoading,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.isShowSettings
            ? _c(
                "div",
                { staticClass: "video-settings whiteBg" },
                [
                  _c("div", { staticClass: "setting-title" }, [
                    _c("i", {
                      staticClass: "iconfont icon-zhedie mr8 pointer",
                      on: {
                        click: function ($event) {
                          _vm.isShowSettings = false
                        },
                      },
                    }),
                    _vm._v(_vm._s(_vm.$t("ai.videoSettings"))),
                  ]),
                  _c(
                    "el-form",
                    {
                      ref: "settingsForm",
                      staticClass: "mt16",
                      attrs: {
                        model: _vm.settings,
                        rules: _vm.rules,
                        "label-width": "120px",
                        "label-position": "top",
                      },
                    },
                    [
                      _c("el-form-item", { staticClass: "flex-row" }, [
                        _c(
                          "div",
                          { staticClass: "flex-row" },
                          [
                            _c("div", { staticClass: "setting-label" }, [
                              _vm._v("Logo："),
                            ]),
                            _vm.settings.logo
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "material-container",
                                    on: {
                                      mouseover: function ($event) {
                                        _vm.settings.hover = true
                                      },
                                      mouseleave: function ($event) {
                                        _vm.settings.hover = false
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "material-thumbnail",
                                      attrs: {
                                        src: _vm.settings.logo,
                                        alt: "material",
                                      },
                                    }),
                                    _vm.settings.hover
                                      ? _c("div", { staticClass: "overlay" }, [
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeMaterial()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("basicInfo.remove")
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _c("picUpload", {
                                  attrs: {
                                    "custom-width": 80,
                                    "custom-height": 80,
                                  },
                                  on: {
                                    picSelectMaterial: _vm.picSelectMaterial,
                                    showLocaPic: _vm.showLocaPic,
                                  },
                                }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "backgroundColor" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex-row-center f-between" },
                            [
                              _c("div", { staticClass: "setting-label" }, [
                                _vm._v(_vm._s(_vm.$t("aiSet.color")) + "："),
                              ]),
                              _c("el-color-picker", {
                                ref: "colorPicker",
                                attrs: { predefine: _vm.predefinedColors },
                                model: {
                                  value: _vm.settings.backgroundColor,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.settings,
                                      "backgroundColor",
                                      $$v
                                    )
                                  },
                                  expression: "settings.backgroundColor",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "backgroundMusicVolume" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex-row-center f-between" },
                            [
                              _c("div", { staticClass: "setting-label" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("aiSet.musicVolume")) + "："
                                ),
                              ]),
                              _vm.settings.backgroundMusicVolume > 0
                                ? _c("i", {
                                    staticClass:
                                      "iconfont icon-shengyin pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setVolume(0)
                                      },
                                    },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "iconfont icon-jingyin pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setVolume(_vm.oldVolume)
                                      },
                                    },
                                  }),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "flex-row-center" },
                            [
                              _c("el-slider", {
                                staticClass: "f-grow",
                                attrs: { step: 0.1, max: 1, min: 0 },
                                on: { change: _vm.changeVolume },
                                model: {
                                  value: _vm.settings.backgroundMusicVolume,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.settings,
                                      "backgroundMusicVolume",
                                      $$v
                                    )
                                  },
                                  expression: "settings.backgroundMusicVolume",
                                },
                              }),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: _vm.$t("editVideo.preview"),
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "iconfont icon-shiting ml10 f-shrink",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.playAudio($event)
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("el-form-item", { attrs: { prop: "dubbing" } }, [
                        _c(
                          "div",
                          { staticClass: "flex-row-center f-between" },
                          [
                            _c("div", { staticClass: "setting-label" }, [
                              _vm._v(
                                _vm._s(_vm.$t("videoMenu.voiceOver")) + "："
                              ),
                            ]),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  placement: "top",
                                  content: _vm.settings.dubbing
                                    ? _vm.$t("subtitle.open")
                                    : _vm.$t("subtitle.close"),
                                },
                              },
                              [
                                _c("el-switch", {
                                  staticStyle: { margin: "10px" },
                                  model: {
                                    value: _vm.settings.dubbing,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.settings, "dubbing", $$v)
                                    },
                                    expression: "settings.dubbing",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm.settings.dubbing
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "speechVoice" } },
                            [
                              _c("div", { staticClass: "setting-label" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("aiSet.speechVoice")) + "："
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-row-center f-between setting-voice",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getVoiceLabel(
                                            _vm.settings.speechVoice
                                          )
                                        )
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "pointer voice-btn",
                                      on: { click: _vm.openVoice },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.settings.speechVoice
                                            ? _vm.$t("change")
                                            : _vm.$t("select")
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        [
                          _c("div", { staticClass: "setting-label" }, [
                            _vm._v(_vm._s(_vm.$t("aiSet.location")) + "："),
                          ]),
                          _c("treeselect", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              options: _vm.productTypelist,
                              normalizer: _vm.normalizer,
                              "load-options": _vm.loadOptions,
                              clearable: false,
                              placeholder: _vm.$t("aiSet.tipLocation"),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "value-label",
                                  fn: function (ref) {
                                    var node = ref.node
                                    return [
                                      _vm._v(" " + _vm._s(node.raw.name) + " "),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1894111289
                            ),
                            model: {
                              value: _vm.settings.productTypeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.settings, "productTypeId", $$v)
                              },
                              expression: "settings.productTypeId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "formFooter" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "submitButton",
                              attrs: {
                                loading: _vm.setLoading,
                                type: "primary",
                              },
                              on: { click: _vm.handleSubmit },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "iconfont icon-sousuotiaojianbaocun",
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("aiSet.save")) + " "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "video-settings-hidden whiteBg pointer" },
                [
                  _c("i", {
                    staticClass: "iconfont icon-zhankai1",
                    on: {
                      click: function ($event) {
                        _vm.isShowSettings = true
                      },
                    },
                  }),
                ]
              ),
          _c("material-drawer", {
            ref: "materialDrawer",
            attrs: { visible: _vm.drawer },
            on: {
              "update:visible": function ($event) {
                _vm.drawer = $event
              },
              selectionConfirmed: _vm.selectionConfirmed,
            },
          }),
          _c(
            "el-drawer",
            {
              attrs: {
                "destroy-on-close": true,
                title: _vm.$t("aiSet.speechVoice"),
                size: "65%",
                visible: _vm.voiceDrawer,
                "with-header": false,
                "before-close": _vm.handleCloseVoice,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.voiceDrawer = $event
                },
              },
            },
            [
              _c("Voice", {
                ref: "voiceRef",
                staticStyle: { padding: "20px" },
                attrs: {
                  voiceList: _vm.voiceList,
                  value: _vm.settings.speechVoice,
                  type: 1,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("AiSettingDialog", {
        ref: "aiSettingDialog",
        attrs: {
          visible: _vm.aiSettingVisible,
          productTypelist: _vm.productTypelist,
          voiceList: _vm.voiceList,
          predefinedColors: _vm.predefinedColors,
          transitionList: _vm.transitionList,
          effectColorStyleList: _vm.effectColorStyleList,
        },
        on: {
          "update:visible": function ($event) {
            _vm.aiSettingVisible = $event
          },
          updateSuccess: _vm.getSetting,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }