<!-- AI视频系统设置弹窗 -->
<template>
  <el-dialog
    class="publicDialog aiSetting"
    :visible.sync="localVisible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="70%"
  >
    <div slot="title">
      <div class="headerFlex">
        <div class="headerTitle">{{ $t('aiSet.systemSetting') }}</div>
        <i
          class="el-icon-close"
          @click="close"
        ></i>
      </div>
    </div>
    <el-form
      v-loading="loading"
      ref="form"
      :model="form"
      :rules="rules"
      label-position="top"
    >
      <div class="set-title">{{$t('aiSet.base')}}</div>
      <el-row :gutter="36">
        <!-- 视频名称 -->
        <el-col :span="8">
          <el-form-item
            :label="$t('aiSet.name')"
            prop="videoName"
          >
            <el-input
              v-model="form.videoName"
              maxlength="50"
              :placeholder="$t('tip.input')"
            />
          </el-form-item>
        </el-col>
        <!-- 保存到 -->
        <el-col :span="8">
          <el-form-item
            :label="$t('aiSet.location')"
            prop="productTypeId"
          >
            <treeselect
              style="width: 100%;"
              v-model="form.productTypeId"
              :options="productTypelist"
              :normalizer="normalizer"
              :load-options="loadOptions"
              :clearable="false"
              :placeholder="$t('aiSet.tipLocation')"
            >
              <template #value-label="{ node }">
                {{ node.raw.name }}
              </template>
            </treeselect>
          </el-form-item>
        </el-col>
        <!-- logo -->
        <el-col :span="8">
          <el-form-item
            label="LOGO"
            prop="logo"
          >
            <AiSetPicUpload
              :isSquare="true"
              :url="form.logo"
              @picSelectMaterial="chooseMaterial(3)"
              @showLocaPic="showLocaPic"
              @clearUrl="clearUrl(3)"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <div class="set-title">{{$t('aiSet.videoSet')}}</div>
      <el-row :gutter="36">
        <!-- 背景音乐 -->
        <el-col :span="8">
          <el-form-item
            :label="$t('aiSet.bgm')"
            prop="backgroundMusicUrl"
          >
            <div
              v-if="form.backgroundMusicUrl"
              class="flex-row-center f-between ai-height"
            >
              <el-tooltip :content="$t('editVideo.preview')">
                <div
                  class="flex-row-center f-grow hidden pointer"
                  @click="playAudio"
                >
                  <i class="iconfont icon-yinpinsucai f-shrink"></i>
                  <span class="single-line-ellipsis f-grow">{{ $t('tip.selected') }}</span>
                </div>
              </el-tooltip>
              <i
                class="iconfont icon-guanbi1 f-shrink pointer"
                @click="clearUrl(2)"
              ></i>
            </div>
            <div
              v-else
              class="flex-row-center f-between ai-height pointer"
              @click="chooseMaterial(2)"
            >
              <span>{{ $t('tip.select') }}</span>
              <i class="iconfont icon-xialajiantou"></i>
            </div>
          </el-form-item>
        </el-col>
        <!-- 视频音色 -->
        <el-col :span="8">
          <el-form-item
            :label="$t('aiSet.speechVoice')"
            prop="speechVoice"
          >
            <div
              class="flex-row-center f-between ai-height pointer"
              @click="openVoice"
            >
              <div
                class="flex-row-center"
                v-if="form.speechVoice"
              >
                {{ getVoiceLabel(form.speechVoice) }}
                <i
                  class="iconfont icon-guanbi1 f-shrink pointer ml10"
                  @click.stop="clearUrl(8)"
                ></i>
              </div>
              <div v-else>{{ $t('tip.select') }}</div>
              <span class="voice-btn">{{ form.speechVoice ? $t('change') : $t('select')}}</span>
            </div>
          </el-form-item>
        </el-col>
        <!-- 背景颜色 -->
        <el-col :span="8">
          <el-form-item
            :label="$t('aiSet.color')"
            prop="backgroundColor"
          >
            <div class="flex-row-center ai-height">
              <el-color-picker
                v-model="form.backgroundColor"
                :predefine="predefinedColors"
              />
            </div>
          </el-form-item>
        </el-col>
        <!-- 封面 -->
        <el-col :span="8">
          <el-form-item
            :label="$t('mixing.cover')"
            prop="coverUrl"
          >
            <AiSetPicUpload
              :isSquare="false"
              :url="form.coverUrl"
              :type="form.coverType"
              @picSelectMaterial="chooseMaterial(4)"
              @showLocaPic="showLocaPic"
              @clearUrl="clearUrl(4)"
            />
          </el-form-item>
        </el-col>
        <!-- 封底 -->
        <el-col :span="8">
          <el-form-item
            :label="$t('mixing.backCover')"
            prop="backCoverUrl"
          >
            <AiSetPicUpload
              :isSquare="false"
              :url="form.backCoverUrl"
              :type="form.backCoverType"
              @picSelectMaterial="chooseMaterial(5)"
              @showLocaPic="showLocaPic"
              @clearUrl="clearUrl(5)"
            />
          </el-form-item>
        </el-col>
        <!-- 转场 -->
        <el-col :span="8">
          <el-form-item
            :label="$t('mixing.transition')"
            prop="transitionList"
          >
            <div
              class="flex-row-center f-between ai-height pointer"
              @click="openTransition"
            >
              <div
                class="flex-row-center"
                v-if="form.transitionList"
              >
                <img
                  :src="getTransitionUrl(form.transitionList)"
                  class="transition-img"
                />
                <i
                  class="iconfont icon-guanbi1 f-shrink pointer ml10"
                  @click.stop="clearUrl(7)"
                ></i>
              </div>
              <div v-else>{{ $t('tip.select') }}</div>
              <span class="voice-btn">{{ form.transitionList ? $t('change') : $t('select')}}</span>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="set-title">{{$t('aiSet.subtitle')}}</div>
      <el-row :gutter="36">
        <!-- 字幕字体 -->
        <el-col :span="8">
          <el-form-item
            :label="$t('aiSet.font')"
            prop="fontType"
          >
            <el-select
              v-model="form.fontType"
              filterable
              :placeholder="$t('subtitle.tipFont')"
              :default-first-option="true"
            >
              <el-option
                v-for="dict in dict.type.font_typeface"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 字幕字号 -->
        <el-col :span="8">
          <el-form-item
            :label="$t('aiSet.fontSize')"
            prop="fontSizeType"
          >
            <el-select
              v-model="form.fontSizeType"
              :placeholder="$t('subtitle.tipFont')"
              :default-first-option="true"
              @change="changeFontSize"
            >
              <el-option
                v-for="dict in fontSizeList"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 字间距 -->
        <el-col :span="8">
          <el-form-item
            :label="$t('aiSet.letterSpacing')"
            prop="spacing"
          >
            <el-input
              v-model="form.spacing"
              type="number"
              :max="20"
              :min="0"
              @input="spacingInput"
              :placeholder="$t('tip.input')"
            />

          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            :label="$t('aiSet.textColor')"
            prop="fontColor"
          >
            <div class="flex-row-center ai-height">
              <el-color-picker v-model="form.fontColor"></el-color-picker>
            </div>
          </el-form-item>
        </el-col>
        <!-- 字幕位置 -->
        <el-col :span="8">
          <el-form-item
            :label="$t('aiSet.center')"
            prop="alignment"
          >
            <el-select
              v-model="form.alignment"
              :placeholder="$t('tip.select')"
              :default-first-option="true"
            >
              <!-- @change="changePosition" -->
              <el-option
                v-for="dict in positionList"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 花字 -->
        <el-col :span="8">
          <el-form-item
            :label="$t('aiSet.decoratedText')"
            prop="effectColorStyleUrl"
          >
            <div
              class="flex-row-center f-between ai-height pointer"
              @click="openColorStyle"
            >
              <div
                class="flex-row-center"
                v-if="form.effectColorStyle"
              >
                <img
                  :src="form.effectColorStyleUrl"
                  class="effectColorStyle-img"
                />
                <i
                  class="iconfont icon-guanbi1 f-shrink pointer ml10"
                  @click.stop="clearUrl(6)"
                ></i>
              </div>
              <div v-else>{{ $t('tip.select') }}</div>
              <span class="voice-btn">{{ form.effectColorStyle ? $t('change') : $t('select')}}</span>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="flex-row-center mt20 f-end">
      <el-button
        class="cancelBtn btnWidth120"
        @click="close"
      >{{ $t('cancel') }}</el-button>
      <el-button
        class="submitBtn btnWidth120"
        :loading="setLoading"
        @click="submit"
      >{{ $t('confirm') }}</el-button>
    </div>
    <!-- 素材库选择抽屉 -->
    <material-drawer
      ref="materialDrawer"
      :visible.sync="drawer"
      @selectionConfirmed="selectionConfirmed"
    />
    <!-- 音色选择抽屉 -->
    <el-drawer
      class="publicDrawer"
      :destroy-on-close="true"
      size="65%"
      :visible.sync="voiceDrawer"
      :with-header="true"
      :before-close="handleCloseVoice"
      :append-to-body="true"
      :show-close="false"
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">{{ $t('aiSet.speechVoice') }}</div>
          <!-- <i
            class="el-icon-close"
            @click="handleClose"
          ></i> -->
        </div>
      </div>
      <Voice
        ref="voiceRef"
        :voiceList="voiceList"
        :value="form.speechVoice"
        :type="1"
        style="padding: 20px;"
      />
      <!-- @confirm="voiceSelected" -->
    </el-drawer>
    <!-- 转场选择抽屉 -->
    <el-drawer
      class="publicDrawer"
      :destroy-on-close="true"
      size="65%"
      :visible.sync="transitionDrawer"
      :with-header="true"
      :append-to-body="true"
      :show-close="false"
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">{{ $t('editVideo.transitionSetup') }}</div>
          <!-- <i
            class="el-icon-close"
            @click="handleClose"
          ></i> -->
        </div>
      </div>
      <Transition
        :transitionList="transitionList"
        :type="1"
        @item-selected="transitionUpdated"
        class="ml20"
      />
      <!-- @confirm="voiceSelected" -->
    </el-drawer>
    <!-- 花字选择抽屉 -->
    <el-drawer
      class="publicDrawer"
      :destroy-on-close="true"
      size="500px"
      :visible.sync="colorStyleDrawer"
      :with-header="true"
      :append-to-body="true"
      :show-close="false"
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">{{ $t('aiSet.speechVoice') }}</div>
          <!-- <i
            class="el-icon-close"
            @click="handleClose"
          ></i> -->
        </div>
      </div>
      <div class="image-container">
        <img
          v-for="item in effectColorStyleList"
          :key="item.id"
          :src="item.imgUrl"
          :class="{ 'selected': form.effectColorStyle === item.effectColorStyle }"
          @click="selectEffectItem(item)"
        />
      </div>
    </el-drawer>
  </el-dialog>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import AiSetPicUpload from "./AiSetPicUpload.vue";
import MaterialDrawer from "@/components/MaterialDrawer/index.vue";
import Voice from "../Voice.vue";
import Transition from "../../aiCutting/Transition.vue";
import { addAiSetting, editAiSetting } from "../../../api/ai/aiSetting";
export default {
  components: {
    Treeselect,
    AiSetPicUpload,
    MaterialDrawer,
    Voice,
    Transition,
  },
  dicts: ["font_typeface"],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    productTypelist: {
      type: Array,
      default: () => [],
    },
    voiceList: {
      type: Array,
      default: () => [],
    },
    predefinedColors: {
      type: Array,
      default: () => [],
    },
    transitionList: {
      type: Array,
      default: () => [],
    },
    effectColorStyleList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localVisible: this.visible,
      loading: false, //当前页面是否正在加载
      drawer: false, //素材选择的抽屉
      chooseType: 0, //选择素材类型：2-音频（背景音乐）、3-logo、4-封面、5-封底
      voiceDrawer: false, //选择配音音色
      transitionDrawer: false, //选择转场效果
      colorStyleDrawer: false, //选择花字效果
      isPlaying: false, // 本组件的播放状态
      currentAudio: null, // 当前播放的音频实例
      setLoading: false, // 设置loading
      fontSizeList: [
        {
          value: "small",
          label: this.$t("subtitle.small"),
        },
        {
          value: "normal",
          label: this.$t("subtitle.normal"),
        },
        {
          value: "big",
          label: this.$t("subtitle.big"),
        },
      ],
      positionList: [
        {
          value: "TopCenter",
          label: this.$t("subtitle.top"),
        },
        {
          value: "CenterCenter",
          label: this.$t("subtitle.center"),
        },
        {
          value: "BottomCenter",
          label: this.$t("subtitle.bottom"),
        },
      ],
      form: {},
      rules: {},
      aiId: "",
    };
  },
  created() {},
  watch: {
    visible(val) {
      this.localVisible = val; // 当 prop 变化时更新本地的 visible
    },
  },
  methods: {
    init(value, aiId) {
      this.form = { ...value };
      this.aiId = aiId;
      console.log("初始化表单", this.form);
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      const countLabel =
        node.productCount > 0 ? `  (${node.productCount})` : "";
      return {
        id: node.id,
        label: `${node.name}${countLabel}`,
        children: node.children,
      };
    },
    loadOptions(node) {
      // 如果节点没有子节点，返回空数组,此接口可以添加检索功能检索是否有子节点
      if (!node || !node.id) {
        node.callback([]);
        return;
      }
      node.callback([]);
    },
    //选择素材：2-音频（背景音乐）、3-logo、4-封面、5-封底
    chooseMaterial(type) {
      this.drawer = true;
      this.chooseType = type;
      let num;
      if (type === 2) {
        num = 2;
      } else if (type === 3) {
        num = 3;
      } else if (type === 4 || type === 5) {
        num = 7;
      }
      this.$nextTick().then((rs) => {
        this.$refs.materialDrawer.$refs.materialLibrary.init(num);
      });
    },
    showLocaPic(items) {
      this.selectionConfirmed(items);
    },
    selectionConfirmed(items) {
      if (items.length == 1) {
        if (this.chooseType == 2) {
          this.form.backgroundMusicUrl = items[0].url;
        } else if (this.chooseType == 3) {
          this.form.logo = items[0].url;
          this.form.logoWidth = items[0].width;
          this.form.logoHeight = items[0].height;
        } else if (this.chooseType == 4) {
          this.form.coverUrl = items[0].url;
          this.form.coverType = items[0].fileType || getTypeByUrl(items[0].url);
        } else if (this.chooseType == 5) {
          this.form.backCoverUrl = items[0].url;
          this.form.backCoverType =
            items[0].fileType || getTypeByUrl(items[0].url);
        }
      }
      console.log("选中的素材:", items);
      // 这里可以执行进一步的处理，例如保存到数据中等
      this.drawer = false;
    },
    //2-音频（背景音乐）、3-logo、4-封面、5-封底、6-花字效果、7-转场、8-音色
    clearUrl(type) {
      console.log("clearUrl");
      if (type == 2) {
        this.form.backgroundMusicUrl = null;
      } else if (type == 3) {
        this.form.logo = null;
        this.form.logoWidth = null;
        this.form.logoHeight = null;
      } else if (type == 4) {
        this.form.coverUrl = null;
        this.form.coverType = null;
      } else if (type == 5) {
        this.form.backCoverUrl = null;
        this.form.backCoverType = null;
      } else if (type == 6) {
        this.form.effectColorStyle = null;
        this.form.effectColorStyleUrl = null;
      } else if (type == 7) {
        this.form.transitionList = null;
      } else if (type == 8) {
        this.form.speechVoice = null;
      }
      this.$forceUpdate();
    },
    playAudio() {
      if (this.isPlaying) {
        // 如果当前正在播放，停止播放
        this.isPlaying = false;
        this.stopAudio();
      } else {
        this.isPlaying = true;
        this.startAudio();
      }
    },
    startAudio() {
      this.currentAudio = new Audio(this.form.backgroundMusicUrl); // 创建新的音频实例
      this.currentAudio.addEventListener("play", this.handlePlay); // 添加播放事件监听
      this.currentAudio.addEventListener("ended", this.handleEnded); // 添加播放完成事件监听
      this.currentAudio.play(); // 播放音频
    },
    stopAudio() {
      if (this.isPlaying && this.currentAudio) {
        this.currentAudio.pause(); // 停止当前播放的音频
        this.currentAudio.currentTime = 0; // 重置播放时间
        this.currentAudio.removeEventListener("play", this.handlePlay); // 移除播放事件监听
        this.currentAudio.removeEventListener("ended", this.handleEnded); // 移除播放完成事件监听
        this.currentAudio = null; // 清空当前音频实例
      }
    },
    handlePlay() {
      this.isPlaying = true;
    },
    handleEnded() {
      this.isPlaying = false;
    },
    getVoiceLabel(voice) {
      if (!voice) return "";
      // 通过遍历 voiceList 找到当前选中的 voice 对象
      for (const category in this.voiceList) {
        const selectedVoice = this.voiceList[category].find(
          (it) => it.voice === voice
        );
        if (selectedVoice) {
          // this.currentVoiceUrl = selectedVoice.voiceUrl
          return selectedVoice.name;
        }
      }
      return "";
    },
    openVoice() {
      this.voiceDrawer = true;
    },
    voiceSelected(voice) {
      this.voiceDrawer = false;
      this.form.speechVoice = voice.voice;
    },
    handleCloseVoice(done) {
      this.$nextTick(() => {
        let voice = this.$refs.voiceRef.getSelect();
        this.form.speechVoice = (voice && voice.voice) || "";
        done();
      });
      this.voiceDrawer = false;
    },
    getTransitionUrl(transition) {
      if (!transition) return "";
      const selected = this.transitionList.find(
        (it) => it.subtype === transition
      );
      if (selected) {
        return selected.url;
      }
      return "";
    },
    openTransition() {
      this.transitionDrawer = true;
    },
    transitionUpdated(value) {
      if (value) {
        this.form.transitionList = value;
      } else {
        this.form.transitionList = null;
      }
      console.log("this.form.transitionList", this.form.transitionList);
    },
    changeFontSize() {
      if (this.form.fontSizeType === "small") {
        this.form.fontSize = 37;
      } else if (this.form.fontSizeType === "big") {
        this.form.fontSize = 70;
      } else {
        this.form.fontSize = 53;
      }
    },
    // changePosition() {
    //   if (this.form.position === "top") {
    //     this.form.y = 0.1;
    //     this.form.alignment = "TopCenter";
    //   } else if (this.form.position === "center") {
    //     this.form.y = 0.5;
    //     this.form.alignment = "CenterCenter";
    //   } else {
    //     this.form.y = 0.9;
    //     this.form.alignment = "BottomCenter";
    //   }
    // },
    spacingInput() {
      if (this.form.spacing > 20) {
        this.form.spacing = 20;
      }
    },
    openColorStyle() {
      this.colorStyleDrawer = true;
    },
    //选中花字
    selectEffectItem(item) {
      console.log("选中花字", item);
      this.form.effectColorStyle = item.effectColorStyle;
      this.form.effectColorStyleUrl = item.imgUrl;
      this.$forceUpdate();
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.setLoading = true;
          this.form.aiVideoId = this.aiId;
          if (this.form.id) {
            editAiSetting(this.form)
              .then((response) => {
                this.$modal.msgSuccess(this.$t("aiSet.success"));
                this.$emit("updateSuccess");
                this.close();
              })
              .finally(() => {
                this.setLoading = false;
              });
          } else {
            addAiSetting(this.form)
              .then((response) => {
                this.$modal.msgSuccess(this.$t("aiSet.success"));
                this.$emit("updateSuccess");
                this.close();
              })
              .finally(() => {
                this.setLoading = false;
              });
          }
        }
      });
    },
    close() {
      this.localVisible = false;
      this.stopAudio();
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.aiSetting {
  .el-form-item {
    margin-bottom: 24px;
    overflow: visible;
  }
  ::v-deep .el-form-item__label {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin: 0 0 -10px 18px;
    padding: 0px 8px;
    z-index: 10;
    position: relative;
    background: #ffffff;
  }

  ::v-deep .el-form-item__content {
    height: 56px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e5e7eb;
    z-index: 9;
    position: relative;
    z-index: auto;
  }
  ::v-deep .el-input--medium .el-input__inner {
    border: none;
    height: 54px !important;
    line-height: 54px !important;
    z-index: 8;
    background: transparent;
  }

  .set-title {
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 22px;
    text-align: left;
    font-style: normal;
  }
  ::v-deep .vue-treeselect__control {
    border: none;
    height: 54px !important;
  }
  ::v-deep .vue-treeselect__value-container {
    height: 54px !important;
    line-height: 54px !important;
  }
  ::v-deep .vue-treeselect__placeholder {
    line-height: 54px;
  }
  ::v-deep .vue-treeselect__single-value {
    line-height: 54px;
  }
  ::v-deep .vue-treeselect__menu-container {
    z-index: 9999 !important;
    position: absolute; // 让菜单浮动显示
    top: 100%; // 让菜单从组件下方展开
    left: 0;
  }
  ::v-deep .vue-treeselect__menu {
    z-index: 9999 !important;
  }
  .el-select {
    width: 100%;
  }
  .ai-height {
    height: 54px;
    width: 100%;
    padding: 0 10px;
    overflow: hidden;
  }
  .icon-yinpinsucai {
    font-size: 14px;
    color: #0156ff;
    margin-right: 4px;
  }
  .voice-btn {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #0156ff;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-decoration-line: underline;
  }

  .effectColorStyle-img {
    max-width: 150px;
    max-height: 50px;
  }
  .option-img {
    max-width: 150px;
    max-height: 50px;
  }
  .transition-img {
    width: 22px;
    height: 22px;
    border-radius: 4px;
    object-fit: cover;
  }

  // * {
  //   max-width: 100%;
  //   box-sizing: border-box; /* 确保内边距和边框都计算在宽度内 */
  // }
}
.image-container {
  width: 100%;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* 图片间距 */
}

.image-container img {
  width: calc(50% - 5px); /* 两个图片一排，减去间距的一半 */
  box-sizing: border-box; /* 包含 padding 和 border */
  padding: 5px;
}
.selected {
  border: 1px solid blue;
  border-radius: 5px;
}
</style>
