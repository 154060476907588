<template>
  <div class="products-container flex-column">
    <div class="right-panel f-grow">
      <div
        class="flex-row-center f-between mb16"
        style="height: 48px;"
      >
        <div class="title-header-text">
          {{ $t('product.product') }}
        </div>
        <div
          v-if="isSelectionMode==0 && quota.fileSize"
          class="flex-row-center"
        >
          <span class="mr5">{{$t('quota.quota')}}：</span>
          <Slider
            :value="formatQuota(quota.useFileSize) || 0"
            :maxValue="formatQuota(quota.fileSize) ||'10G' "
          />
          <div class="ml5">
            {{ quota.useFileSize || 0}}/{{ quota.fileSize || '10G'}}
          </div>
        </div>
      </div>
      <!-- 视频成片内容 -->
      <div>
        <div class="flex-row-center f-between w-100">
          <div class="f-shrink">
            <!-- 创建文件夹按钮（第一层和第二层） -->
            <el-button
              class="gradient-button"
              icon="iconfont icon-daochu"
              @click="addMaterial"
              v-if="isSelectionMode==0"
            >{{ $t('product.upload') }}</el-button>
            <el-button
              class="normal-button"
              icon="iconfont icon-tianjia1"
              @click="openAddDialog"
              v-if="isSelectionMode==0 && currentLevel < 2"
            >{{$t('file.createFolder')}}</el-button>
            <el-button
              class="delete-button"
              icon="iconfont icon-delete"
              @click="deleteMultiple"
              v-if="isSelectionMode==0 && ids.length > 0"
            >{{$t('remove')}}</el-button>
          </div>
          <div class="flex-row-center">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('refresh')"
              placement="top"
            >
              <i
                class="iconfont icon-a-danyuanpeizhi6"
                @click="refresh"
              ></i>
            </el-tooltip>
            <el-radio-group
              v-model="styleType"
              class="custom-radio-group"
              v-if="isSelectionMode==0"
            >
              <el-radio
                label="grid"
                :border="styleType === 'grid'"
              >
                <template v-slot:default>
                  <i
                    class="iconfont font20 icon-grid"
                    :class="styleType === 'grid' ? 'gradient-purple' : 'unbind'"
                  ></i>
                </template>
              </el-radio>
              <el-radio
                label="list"
                :border="styleType === 'list'"
              >
                <template v-slot:default>
                  <i
                    class="iconfont font20 icon-list"
                    :class="styleType === 'list' ? 'gradient-purple' : 'unbind'"
                  ></i>
                </template>
              </el-radio>
            </el-radio-group>
            <div style="width: 400px;margin-right: 5px;">
              <el-input
                class="account-input"
                v-model="queryParams.searchValue"
                :placeholder="$t('product.tipName')"
                @keyup.enter.native="handleQuery"
                @change="searchValueChange"
                clearable
              >
                <i
                  slot="prefix"
                  class="el-input__icon iconfont icon-sousuotubiao"
                  @click="handleQuery"
                ></i>
              </el-input>
            </div>
          </div>
          <!-- <el-form class="searchForm flex-row-center f-wrap" :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch">
              <el-form-item label="">
                <el-select v-model="queryParams.labels" @change="handleQuery" clearable
                           :placeholder="$t('label.label')" style="width: 200px" allow-create filterable multiple>
                  <el-option v-for="dict in labelList" :key="dict.id" :label="dict.label" :value="dict.label"/>
                </el-select>
              </el-form-item>
            </el-form> -->
        </div>
        <!-- <el-row class="flex-row-center">
            <el-form class="f-grow" :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch">
              <el-form-item label="">
                <el-select v-model="queryParams.labels" @change="handleQuery" clearable
                  :placeholder="$t('label.label')" style="width: 200px" allow-create filterable multiple>
                  <el-option v-for="dict in labelList" :key="dict.id" :label="dict.label" :value="dict.label" />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">{{$t('search')}}</el-button>
              </el-form-item>
            </el-form>
          </el-row> -->

        <div v-loading="loading">
          <div>
            <!-- <div class="flex-row-center" v-if="currentPath.length > 0" >
                <el-button type="text" @click="goBack">← {{$t('file.goBack')}}</el-button>
                <div class="ml10 mr10">|</div>
                <div class="breadcrumb">
                  <span v-for="(folder, index) in currentPathFolders" :key="folder.id" @click="navigateTo(index)">
                    {{ folder.name }} <span v-if="index < currentPath.length - 1">/</span>
                  </span>
                </div>
              </div> -->
            <div
              class="flex-row-center fileSpace"
              v-if="currentPath.length > 0 || isSearchResult"
            >
              <div class="breadcrumb">
                <span
                  @click="goLast"
                  v-if="!isSearchResult"
                >← {{$t('file.goBack')}}</span>
                <span
                  class="ml10"
                  @click="goBack"
                >{{$t('file.goBackWorks')}}&nbsp;/</span>
              </div>
              <div
                class="breadcrumb"
                style="margin-left: 5px;"
              >
                <span
                  v-for="(folder, index) in currentPathFolders"
                  :key="folder.id"
                  @click="navigateTo(index)"
                >
                  {{ folder.name }} <span v-if="index < currentPath.length - 1">&nbsp;/&nbsp;</span>
                </span>
              </div>
              <span
                v-if="isSearchResult"
                class="ml10"
              >{{ $t('file.searchResult') }}</span>
              <el-button
                class="delete-button ml20"
                icon="iconfont icon-guanbi1"
                @click="closeSearch"
                v-if="isSearchResult"
              >{{$t('file.closeSearch')}}</el-button>
            </div>
            <!-- 显示当前文件夹 -->
            <div
              class="flex-row f-wrap folders"
              v-if="styleType === 'grid' && !isSearchResult"
              style="height: calc(100vh - 350px); overflow-y: auto;"
            >
              <folder
                class="video-parent"
                v-for="folder in currentFolders"
                :key="folder.id"
                :folder="folder"
                @open-folder="openFolder"
                @edit-folder="openEditDialog"
                @delete-folder="deleteProductType"
              />
              <video-grid-item
                class="video-parent"
                v-for="(video, index) in productList"
                :key="index"
                :video="video"
                :isSelectionMode="isSelectionMode!=0"
                @delete-video="deleteVideo"
                @view-text="viewVideo"
                @edit-video="editVideo"
                @toggle-selection="handleToggleSelection"
                :type="3"
              />
            </div>
          </div>
          <div
            class="flex-row f-wrap"
            v-if="styleType === 'grid'&& isSearchResult"
            style="height: calc(100vh - 350px); overflow-y: auto;"
          >
            <video-grid-item
              class="video-parent"
              v-for="(video, index) in productList"
              :key="index"
              :video="video"
              :isSelectionMode="isSelectionMode!=0"
              @delete-video="deleteVideo"
              @view-text="viewVideo"
              @edit-video="editVideo"
              @toggle-selection="handleToggleSelection"
              :type="3"
            />
          </div>
          <div
            class="w-100 mt10"
            v-if="styleType === 'list'"
          >
            <video-list-table
              :videoList="mergedList"
              :isSelectionMode="isSelectionMode!=0"
              @open-folder="openFolder"
              @edit-folder="openEditDialog"
              @delete-folder="deleteProductType"
              @delete-video="deleteVideo"
              @view-text="viewVideo"
              @edit-video="editVideo"
              @toggle-selection="handleToggleSelection"
              @select-multiple="handleSelectMultiple"
            />
          </div>
          <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            :pageSizes="[12,24,36,48,60]"
            @pagination="getProductList"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isSelectionMode!=0"
      class="f-shrik flex-center mt10"
      style="background:white"
    >
      <el-button
        class="button-white"
        @click="$emit('cancel')"
      >{{ $t('cancel') }}</el-button>
      <el-button
        class="gradient-button"
        @click="confirmSelection"
      >{{ $t('confirmSelection') }}</el-button>
    </div>
    <EditDialog
      :visible.sync="editDialogVisible"
      :title="editDialogTitle"
      :initialValue="initialInput"
      :initialLabel="initialLabel"
      :labelList="labelList"
      @confirm="handleConfirm"
    />
    <el-dialog
      class="publicDialog"
      :visible.sync="uploadDialogVisible"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">{{ uploadTitle }}</div>
          <i
            class="el-icon-close"
            @click="uploadDialogVisible = false"
          ></i>
        </div>
      </div>
      <el-form
        :model="uploadForm"
        ref="uploadForm"
        label-width="80px"
        :rules="rules"
        label-position="left"
      >
        <el-form-item
          :label="$t('file.file')"
          prop="fileList"
          v-if="uploadTitle==$t('file.upload')"
        >
          <videoUploadMultifile
            v-model="uploadForm.fileList"
            :limit="10"
            :fileType="fileTypes"
          />
        </el-form-item>
        <el-form-item
          :label="$t('file.name')"
          prop="name"
          v-if="uploadTitle==$t('edit')"
        >
          <el-input
            v-model="uploadForm.name"
            maxlength="50"
            :placeholder="$t('tip.input')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('material.position')"
          prop="productTypeId"
        >
          <!-- <el-select v-model="uploadForm.productTypeId" :placeholder="$t('file.tipSelectFolder')">
            <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select> -->
          <treeselect
            v-model="uploadForm.productTypeId"
            :options="list"
            :normalizer="normalizer"
            :clearable="false"
            :placeholder="$t('file.tipSelectFolder')"
          >
            <template #value-label="{ node }">
              {{ node.raw.name }}
            </template>
          </treeselect>
          <!-- :show-count="true" -->
        </el-form-item>
        <el-form-item
          :label="$t('file.description')"
          prop="description"
        >
          <el-input
            v-model="uploadForm.description"
            maxlength="200"
            :placeholder="$t('tip.input')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('label.label')">
          <el-select
            v-model="uploadForm.labels"
            :placeholder="$t('label.tipSelect')"
            default-first-option
            allow-create
            filterable
            multiple
            style="width:100%;"
          >
            <el-option
              v-for="dict in labelList"
              :key="dict.id"
              :label="dict.label"
              :value="dict.label"
            ></el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          class="cancelBtn"
          @click="uploadDialogVisible = false"
        >{{$t('cancel')}}</el-button>
        <el-button
          class="submitBtn"
          :loading="buttonLoading"
          type="primary"
          @click="submitUpload"
        >{{$t('confirm')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import VideoGridItem from "../../components/Material/VideoGridItem.vue";
import VideoListTable from "../../components/Material/VideoListTable.vue";
import VideoUploadMultifile from "../../components/VideoUpload/multifile.vue";
import EditDialog from "../../components/dialog/editDialog.vue";
import Folder from "../../components/video/Folder.vue";
import Slider from "../account/slider.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {
  addProductType,
  editProductType,
  listProductType,
} from "../../api/ai/productType";
import {
  addProduct,
  editProduct,
  listProduct,
  removeProductAll,
} from "../../api/ai/product";
import { listLabel } from "../../api/system/label";
import EventBus from "../../utils/EventBus";
import { getQuota } from "../../api/system/merchant";

export default {
  // name: "GeneratedVideo",
  components: {
    VideoGridItem,
    VideoListTable,
    VideoUploadMultifile,
    EditDialog,
    Folder,
    Slider,
    Treeselect,
  },
  // dicts: ['material_label'],
  data() {
    return {
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 选中数组
      ids: [],
      usernames: [],
      queryParams: {
        pageNum: 1,
        pageSize: 24,
        labels: [],
        searchValue: "",
        productTypeId: 0,
      },
      isSearchResult: false, //是否为搜索结果
      // 显示搜索条件
      showSearch: true,
      list: [
        {
          id: 0,
          name: this.$t("product.product"),
          type: "system",
        },
      ],
      folders: [], //文件夹列表，与list相同，没有‘全部’
      editDialogVisible: false,
      editDialogTitle: this.$t("file.editFolder"),
      editProductId: null,
      initialInput: "", //默认分组名称
      initialLabel: "", //默认分类标签
      initialParentId: "", //默认父文件夹id
      uploadDialogVisible: false, //成片弹窗
      buttonLoading: false, //提交的loading
      uploadForm: {
        fileList: [],
        ossId: "",
        url: "",
        name: "",
        description: "",
        productTypeId: 0,
        labels: [],
        label: "",
        fileType: "",
      },
      rules: {
        fileList: [
          {
            required: true,
            message: this.$t("file.tipSelect"),
            trigger: ["blur", "change"],
          },
        ],
        name: [
          {
            required: true,
            message: this.$t("file.tipName"),
            trigger: ["blur", "change"],
          },
        ],
        // productTypeId: [
        //   {required: true, message: this.$t('file.tipSelectFolder'), trigger: ['blur', 'change']}
        // ],
      },
      isSelectionMode: 0, // 0-默认值为 1-单选 2-多选
      productList: [],
      uploadTitle: this.$t("edit"),
      labelList: [], //标签
      currentPath: [], // 用于追踪当前路径
      currentTypeId: 0, //当前打开的文件夹/素材id
      // fileTypes:["mp4", "avi", "mkv","webm", "mpeg", "asf","mov","wmv","3gp","rm","rmvb","flv","f4v"],
      fileTypes: ["mp4", "avi", "mkv", "mov"],
      styleType: "grid", //grid or list
      quota: {}, //配额
      selectedMultiple: [], //选中的素材
      selectedOrder: [], // 新增：记录选中顺序的数组
    };
  },
  computed: {
    currentLevel() {
      return this.currentPath.length;
    },
    currentFolders() {
      let level = this.folders;
      let validPath = true;

      this.currentPath.forEach((id) => {
        if (validPath) {
          const folder = level.find((folder) => folder.id === id);
          if (folder && folder.children) {
            level = folder.children;
          } else {
            validPath = false; // 如果路径无效，停止继续寻找
            level = [];
          }
        }
      });
      return level;
    },
    mergedList() {
      // 使用 map 为 currentFolders 添加 type: 'folder'
      const foldersWithType = this.currentFolders.map((folder) => {
        // 处理 label 字段
        const labels = folder.label ? folder.label.split(",") : [];
        return {
          ...folder,
          documentType: "folder",
          labels, // 添加 labels 数组
        };
      });
      // 使用 map 为 productList 添加 type: 'file'
      const materialsWithType = this.productList.map((material) => ({
        ...material,
        documentType: "file",
      }));

      // 合并文件夹和文件，文件夹优先展示
      return [...foldersWithType, ...materialsWithType];
    },
    currentPathFolders() {
      let pathFolders = [];
      let level = this.folders;
      this.currentPath.forEach((id) => {
        const folder = level.find((folder) => folder.id === id);
        if (folder) {
          pathFolders.push(folder);
          level = folder.children ? folder.children : [];
        }
      });
      return pathFolders;
    },
  },
  mounted() {
    // 初始化数据
    this.init(false);
    this.getQuota();
    this.getLabels();
    EventBus.$on("file-success", this.refresh);
  },
  beforeDestroy() {
    // 在组件销毁前取消事件监听
    EventBus.$off("file-success", this.refresh);
  },
  methods: {
    //获取配额
    getQuota() {
      getQuota().then((response) => {
        this.quota = response.data;
      });
    },
    refresh() {
      this.getLabels();
      this.getProductList();
      this.getProductTypeList();
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getProductList();
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      const countLabel =
        node.productCount > 0 ? `  (${node.productCount})` : "";
      return {
        id: node.id,
        label: `${node.name}${countLabel}`,
        children: node.children,
      };
    },
    openFolder(folder) {
      this.currentPath.push(folder.id);
      console.log(this.currentPath);
      this.handleItemClick(folder.id);
    },
    // 上一步
    goLast() {
      if (this.currentPath.length > 0) {
        this.currentPath.pop();
        let id =
          this.currentPath.length > 0
            ? this.currentPath[this.currentPath.length - 1]
            : 0;
        this.currentTypeId = id;
        this.handleItemClick(id);
      }
    },
    // 返回作品库
    goBack() {
      if (this.currentPath.length > 0) {
        this.currentPath = [];
        this.handleItemClick(0);
      }
    },
    navigateTo(index) {
      this.currentPath = this.currentPath.slice(0, index + 1);
    },
    getLabels() {
      listLabel().then((response) => {
        this.labelList = response.rows;
      });
    },
    init(isSelectionMode) {
      this.isSelectionMode = isSelectionMode;
      this.getProductList();
      this.getProductTypeList();
    },
    // 关闭搜索
    closeSearch() {
      this.isSearchResult = false;
      this.queryParams.searchValue = null;
      this.getProductList();
    },
    searchValueChange() {
      if (this.isSearchResult && !this.queryParams.searchValue) {
        this.isSearchResult = false;
        this.getProductList();
      }
    },
    //成片列表（视频、图片）
    getProductList() {
      this.loading = true;
      if (this.queryParams.productTypeId == 0) {
        this.queryParams.productTypeId = null;
      }
      if (this.queryParams.searchValue) {
        this.isSearchResult = true;
      } else {
        this.isSearchResult = false;
      }
      listProduct(this.queryParams)
        .then((response) => {
          this.productList = response.rows;
          this.total = response.total;
          this.productList.forEach((item) => {
            item.selected = false;
            item.hover = false;
            if (item.label) {
              item.labels = item.label.split(",");
            } else {
              item.labels = [];
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //成片重置查询
    resetQuery() {
      this.queryParams.labels = [];
      this.queryParams.name = "";
      this.queryParams.productTypeId = 0;
      this.getProductList();
      if (this.folders.length == 0) {
        this.getProductTypeList();
      }
    },
    resetForm() {
      this.uploadForm = {
        fileList: [],
        ossId: "",
        url: "",
        name: "",
        description: "",
        productTypeId: this.currentTypeId,
        labels: [],
        label: "",
        fileType: "",
      };
    },
    //新增成片（视频、图片）
    addMaterial() {
      if (this.isExceedQuota(this.quota.useFileSize, this.quota.fileSize)) {
        this.$modal.msgError(this.$t("material.exceedQuota"));
        return;
      }
      this.uploadTitle = this.$t("file.upload");
      this.resetForm();
      this.uploadDialogVisible = true;
    },
    // 查看详情
    viewVideo(row) {
      this.$router.push({
        path: `/worksDetail/${row.id}`,
      });
    },
    //编辑成片（视频、图片）
    editVideo(item) {
      this.uploadTitle = this.$t("edit");
      this.uploadForm = { ...item }; // 使用浅拷贝确保数据绑定正确
      if (this.uploadForm.label) {
        this.uploadForm.labels = this.uploadForm.label.split(",");
      }
      this.uploadDialogVisible = true;
    },
    //增改提交
    submitUpload() {
      this.$refs.uploadForm.validate((valid) => {
        if (valid) {
          // if(this.uploadTitle==this.$t('file.upload')){
          //   this.uploadForm.ossId = this.uploadForm.fileList[0].ossId
          //   this.uploadForm.url = this.uploadForm.fileList[0].url
          //   this.uploadForm.fileType = this.getTypeByUrl(this.uploadForm.url)
          // }

          if (this.uploadForm.labels.length > 0) {
            this.uploadForm.label = this.uploadForm.labels.join(",");
          } else {
            this.uploadForm.label = "";
          }
          this.buttonLoading = true;
          if (this.uploadTitle == this.$t("file.upload")) {
            EventBus.$emit("post-file", this.uploadForm, "product");
            this.buttonLoading = false;
            this.uploadDialogVisible = false;
            // addProduct(this.uploadForm).then(res => {
            //   this.uploadDialogVisible = false;
            //   this.getProductList();
            // }).catch(() => {
            // })
          } else {
            editProduct(this.uploadForm)
              .then((res) => {
                this.uploadDialogVisible = false;
                this.getProductList();
                this.getLabels(); //新增或编辑时可能对标签产生修改，需要刷新
                this.getProductTypeList(); //新增或编辑时会对文件数量产生修改，需要刷新
              })
              .finally(() => {
                this.buttonLoading = false;
              });
          }
        }
      });
    },
    //删除成片（视频、图片）
    deleteVideo(video) {
      this.$confirm(this.$t("product.tipDelete"), this.$t("tip.tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        // 处理删除逻辑
        removeProductAll({ ids: [video.id] }).then((response) => {
          this.$modal.msgSuccess(this.$t("tip.remove"));
          this.getProductList();
        });
      });
    },
    //多选、单选都存在的情况，需要用方法重新处理下逻辑，直接在模板中使用三元运算符会导致事件绑定的问题，致使方法没有响应
    handleToggleSelection(selected, item) {
      if (this.isSelectionMode === 1) {
        this.toggleSelectionSingle(selected, item);
      } else {
        // 多选模式
        if (selected) {
          // 如果是选中，添加到选中顺序数组
          if (!this.selectedOrder.includes(item.id)) {
            this.selectedOrder.push(item.id);
          }
        } else {
          // 如果是取消选中，从选中顺序数组中移除
          const index = this.selectedOrder.indexOf(item.id);
          if (index > -1) {
            this.selectedOrder.splice(index, 1);
          }
        }
        this.toggleSelection(selected, item);
      }
    },
    // 单选
    toggleSelectionSingle(selected, video) {
      console.log("selected", selected);
      // 取消所有其他项目的选择
      this.productList = this.productList.map((v) => ({
        ...v,
        selected: false,
      }));

      // 选择当前点击的项目
      const index = this.productList.findIndex((v) => v.id === video.id);
      if (index !== -1) {
        this.$set(this.productList, index, {
          ...this.productList[index],
          selected,
        });
      }
      console.log("this.productList", this.productList);
    },
    // 多选
    toggleSelection(selected, video) {
      console.log("toggleSelection", selected);
      const index = this.productList.findIndex((v) => v.id === video.id);
      if (index !== -1) {
        this.$set(this.productList, index, {
          ...this.productList[index],
          selected,
        });
        // this.productList[index].selected = selected;
      }
      console.log("this.productList", this.productList);
    },
    //成片分组列表
    getProductTypeList() {
      listProductType().then((response) => {
        this.folders = response.rows;
        this.folders.forEach((item) => {
          item.hover = false;
        });
        this.list = JSON.parse(JSON.stringify(response.rows));
        this.list.unshift({
          id: 0,
          name: this.$t("product.product"),
          type: "system",
        });
      });
    },
    handleItemClick(index) {
      // 处理菜单项点击的逻辑
      console.log("Clicked item with index:", index);
      this.queryParams.productTypeId = index;
      this.currentTypeId = index;
      this.getProductList();
    },
    openEditDialog(item) {
      this.initialInput = item.name;
      this.initialLabel = item.label;
      this.initialParentId = item.parentId || 0;
      this.editProductId = item.id;
      this.editDialogTitle = this.$t("file.editFolder");
      this.editDialogVisible = true;
    },
    openAddDialog() {
      this.initialInput = "";
      this.initialLabel = "";
      this.initialParentId =
        (this.currentPath &&
          this.currentPath.length > 0 &&
          this.currentPath[this.currentPath.length - 1]) ||
        0;
      this.editProductId = null;
      this.editDialogTitle = this.$t("file.createFolder");
      this.editDialogVisible = true;
    },
    handleConfirm(form) {
      console.log("输入的内容:", form);
      if (this.editDialogTitle == this.$t("file.editFolder")) {
        editProductType({
          id: this.editProductId,
          name: form.input,
          parentId: this.initialParentId || 0,
          label: form.labels.join(","),
        }).then((response) => {
          this.$modal.msgSuccess(this.$t("tip.update"));
          this.getProductTypeList();
        });
      } else {
        // 处理输入内容，例如发送请求或更新数据
        addProductType({
          name: form.input,
          parentId: this.initialParentId || 0,
          label: form.labels.join(","),
        }).then((response) => {
          this.$modal.msgSuccess(this.$t("tip.add"));
          this.getProductTypeList();
        });
      }
    },
    deleteProductType(item) {
      this.$confirm(this.$t("file.tipDeleteFolderSingle"), this.$t("tip.tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        // 处理删除逻辑
        removeProductAll({ typeIds: [item.id] }).then((response) => {
          this.$modal.msgSuccess(this.$t("tip.remove"));
          this.getProductTypeList();
        });
      });
    },
    // 多选框选中数据
    handleSelectMultiple(selection) {
      this.selectedMultiple = selection || [];
      this.ids = selection.map((item) => item.id) || [];
    },
    deleteMultiple() {
      // 判断选中的项中是否有 documentType 为 'system' 的项
      const hasSystemDocumentType = this.selectedMultiple.some(
        (item) => item.documentType === "folder"
      );
      const tip = hasSystemDocumentType
        ? this.$t("file.tipDeleteSystemFolder")
        : this.$t("file.tipDeleteFolder");
      this.$confirm(tip, this.$t("tip.tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        const typeIds =
          this.selectedMultiple
            .filter((item) => item.documentType === "folder")
            .map((item) => item.id) || [];
        const ids =
          this.selectedMultiple
            .filter((item) => item.documentType !== "folder")
            .map((item) => item.id) || [];
        removeProductAll({ typeIds, ids }).then((response) => {
          this.$modal.msgSuccess(this.$t("tip.remove"));
          this.getProductTypeList();
          this.getProductList();
        });
      });
    },
    confirmSelection() {
      // 确认选择操作
      let selectedItems = this.productList.filter((item) => item.selected);
      if (selectedItems.length == 0) {
        this.$modal.msgError(this.$t("product.tipVideo"));
        return;
      }

      // 根据选中顺序重新排序
      if (this.selectedOrder.length > 0) {
        selectedItems.sort((a, b) => {
          return (
            this.selectedOrder.indexOf(a.id) - this.selectedOrder.indexOf(b.id)
          );
        });
      }
      console.log("确认选择", selectedItems);
      this.$emit("selectionConfirmed", selectedItems);
      // this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.products-container {
  width: 100%;
  padding: 24px;
}

.right-panel {
  height: calc(100vh - 145px);
  overflow-y: auto;
}

.fileSpace {
  padding: 15px 0px 10px 5px;
}
.breadcrumb {
  margin-bottom: 0px;
}
.breadcrumb span {
  cursor: pointer;
  color: #00aaff;
}
.breadcrumb span:hover {
  text-decoration: underline;
}
.folders,
.files {
  margin-top: 10px;
}
.folders div,
.files div {
  cursor: pointer;
  /* margin-bottom: 5px; */
}
// .folders div:hover,
// .files div:hover {
//   text-decoration: underline;
// }
.custom-radio-group {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
}
.custom-radio-group ::v-deep.el-radio {
  margin-right: 5px;
}
.custom-radio-group ::v-deep.el-radio.is-bordered.is-checked {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}
.custom-radio-group ::v-deep.el-radio__input {
  display: none; /* 隐藏原点 */
}
.video-parent {
  width: calc(16.6% - 29px);
}
i.iconfont.icon-daochu,
i.iconfont.icon-tianjia1 {
  margin-right: 6px;
}
</style>
