<!-- AI视频系统设置弹窗中的图片选择/上传 -->
<template>
  <div
    class="uploadboxPic"
    v-loading="loading"
  >
    <el-dropdown
      trigger="hover"
      class="w-100"
    >
      <div class="local flex-row-center f-between">
        <span>{{ url ? $t('file.uploaded'):$t('file.clickUpload') }}</span>
        <div class="flex-row-center">
          <video
            v-if="url && type === 'video'"
            :src="url"
            alt="logo and so on"
            :class="isSquare ? 'choosedPicSquare' : 'choosedPic'"
          />
          <img
            v-if="url && type === 'image'"
            :src="url"
            alt="logo and so on"
            :class="isSquare ? 'choosedPicSquare' : 'choosedPic'"
          />
          <i
            v-if="url"
            class="iconfont icon-guanbi1 pointer"
            @click="$emit('clearUrl')"
          ></i>
          <i class="iconfont icon-daochu"></i>
        </div>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <el-upload
            action=""
            accept="image/*"
            :before-upload="handleBeforeUpload"
            :limit="1"
          >
            {{ $t('file.localUpload') }}
          </el-upload>
        </el-dropdown-item>
        <el-dropdown-item>
          <div @click="picSelectMaterial">{{ $t('file.selectMaterial') }}</div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { localTuClipAdd } from "../../../api/ai/material";
import { getOssToken } from "../../../api/ai/ali";
import OSS from "ali-oss";
export default {
  props: {
    isSquare: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
    },
    type: {
      type: String,
      default: "image",
    },
  },
  watch: {
    // url: {
    //   handler(val) {
    //     if (!val) {
    //       this.$emit("clearUrl");
    //     }
    //   },
    //   immediate: true,
    // }
  },
  data() {
    return {
      credentials: null,
      loading: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    picSelectMaterial() {
      this.$emit("picSelectMaterial");
    },
    async uploadFile(file) {
      console.log(file);
      try {
        this.loading = true;
        const client = await this.getOssClient();
        const result = await client.put(
          `file/${this.getCurrentDate()}/${this.generateUniqueCode(
            30
          )}${file.name.replace(/^[^.]+/, "")}`,
          file
        );
        console.log(result, "上传至oss的文件");
        localTuClipAdd({
          ...file,
          fileType: "image",
          url: result.url,
          fileSize: file.size,
          name: file.name.replace(/\.[^/.]+$/, ""),
        })
          .then((response) => {
            console.log(response);
            this.$emit("showLocaPic", [{ url: response.data.url }]);
            // this.$emit('showLocaPic', [{url: result.url}])
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getOssClient() {
      // 临时凭证过期时重新获取，减少对STS服务的调用。
      if (this.isCredentialsExpired(this.credentials)) {
        this.credentials = await getOssToken();
      }
      return new OSS({
        bucket: process.env.VUE_APP_OSS_BUCKET, // OSS Bucket名称
        region: "oss-cn-beijing", // OSS所在地域
        accessKeyId: this.credentials.accessKeyId,
        accessKeySecret: this.credentials.accessKeySecret,
        stsToken: this.credentials.securityToken,
        timeout: 500000,
        secure: true,
      });
    },
    isCredentialsExpired(credentials) {
      if (!credentials) {
        return true;
      }
      const expireDate = new Date(credentials.Expiration);
      const now = new Date();
      return expireDate.getTime() - now.getTime() <= 60000; // 有效期不足一分钟，视为过期
    },
    handleBeforeUpload(file) {
      // 校检文件类型
      if (this.fileType) {
        const fileName = file.name.split(".");
        const fileExt = fileName[fileName.length - 1];
        const isTypeOk = this.fileType.indexOf(fileExt) >= 0;
        if (!isTypeOk) {
          this.$modal.msgError(
            `${this.$t("file.invalidFormat1")}${this.fileType.join(
              "/"
            )}${this.$t("file.invalidFormat2")}`
          );
          return false;
        }
      }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$modal.msgError(
            `${this.$t("file.sizeExceed")} ${this.fileSize} MB!`
          );
          return false;
        }
      }
      this.uploadFile(file);
      // this.$modal.loading("正在上传文件，请稍候...");
      this.number++;
      return true;
    },
    generateRandomCode(length = 30) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
      return result;
    },
    generateUniqueCode(length) {
      const generatedCodes = new Set();
      let code;
      do {
        code = this.generateRandomCode(length);
      } while (generatedCodes.has(code));
      generatedCodes.add(code);
      return code;
    },
    getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，所以加1
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}/${month}/${day}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadboxPic {
  width: 100%;
  height: 54px;
}

.local {
  width: 100%;
  height: 54px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #9a9a9a;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  padding: 0 10px;
  cursor: pointer;
}
.choosedPic {
  width: 22px;
  height: 39px;
  border-radius: 4px;
  object-fit: cover;
}
.choosedPicSquare {
  width: 22px;
  height: 22px;
  border-radius: 4px;
  object-fit: cover; /* 确保视频不会拉伸 */
}
.icon-guanbi1 {
  font-size: 14px;
  color: #9a9a9a;
  margin-left: 12px;
}
.icon-daochu {
  font-size: 14px;
  color: #3377ff;
  margin-left: 12px;
}
</style>
